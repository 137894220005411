var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import RoutePath from '../constant/route';
import './index.scss';
var MobileHeader = function MobileHeader(_a) {
  var topLock = _a.topLock;
  var _b = useState(""),
    subMenu = _b[0],
    setSubMenu = _b[1];
  var _c = useState(false),
    isShowDrawer = _c[0],
    setShowDrawer = _c[1];
  var t = useTranslation().t;
  useEffect(function () {
    window.scrollTo(0, 0);
    if (!isShowDrawer) {
      document.body.className = '';
    } else {
      document.body.className = 'hidden';
    }
  }, [isShowDrawer]);
  var changeSubMenu = function changeSubMenu(menu) {
    if (subMenu === menu) {
      setSubMenu('');
      return;
    }
    setSubMenu(menu);
  };
  var toggleDrawer = function toggleDrawer() {
    if (topLock) {
      topLock(isShowDrawer);
    }
    setShowDrawer(function (prev) {
      return !prev;
    });
  };
  var moveToBoard = function moveToBoard() {
    if (location.hostname.includes('localhost')) {
      window.location.href = 'http://localhost:3000/board';
      return;
    }
    window.location.href = "/".concat(RoutePath.BOARD);
  };
  var openWindow = function openWindow() {
    window.open('https://race981.page.link/web_hero?utm_source=direct&utm_medium=button&utm_campaign=hero');
  };
  return _jsx("header", __assign({
    className: "mobile-header"
  }, {
    children: _jsxs("div", __assign({
      className: "head-wrap"
    }, {
      children: [_jsx("h1", __assign({
        className: "logo"
      }, {
        children: _jsx("a", __assign({
          href: "/" + RoutePath.HOME
        }, {
          children: _jsx("img", {
            src: "https://asset.981park.com/www/common/mweb/img/common/logo/981-park.svg",
            alt: "981 PARK"
          })
        }))
      })), _jsx("button", __assign({
        type: "button",
        className: "btn btn-menu" + (isShowDrawer ? " selected" : ""),
        onClick: toggleDrawer
      }, {
        children: _jsx("span", __assign({
          className: "blind"
        }, {
          children: "menu"
        }))
      })), _jsx("nav", __assign({
        className: "accordion",
        style: {
          display: isShowDrawer ? "block" : "none"
        }
      }, {
        children: _jsxs("div", __assign({
          className: "inner"
        }, {
          children: [_jsxs("ul", __assign({
            className: "gnb"
          }, {
            children: [_jsxs("li", {
              children: [_jsx("a", __assign({
                className: "link-gnb typo1" + (subMenu === "park" ? " sub-menu-open" : ""),
                onClick: function onClick() {
                  return changeSubMenu("park");
                }
              }, {
                children: "PARK"
              })), _jsxs("div", __assign({
                className: "sub-menu",
                style: {
                  display: subMenu === "park" ? "block" : "none"
                }
              }, {
                children: [_jsx("a", __assign({
                  href: "/" + RoutePath.PARK,
                  className: "depth-1",
                  onClick: toggleDrawer
                }, {
                  children: "PARK INFORMATION"
                })), _jsxs("ul", __assign({
                  className: "depth-3-wrap"
                }, {
                  children: [_jsx("li", {
                    children: _jsx("a", __assign({
                      onClick: moveToBoard
                    }, {
                      children: t('Park_sub0_title')
                    }))
                  }), _jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.ACTIVITY,
                      onClick: toggleDrawer
                    }, {
                      children: t('Park_sub1_title')
                    }))
                  }), _jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.PARKFLEX,
                      onClick: toggleDrawer
                    }, {
                      children: t('Park_sub2_title')
                    }))
                  }), _jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.CONVENIENT,
                      onClick: toggleDrawer
                    }, {
                      children: t('Park_sub3_title')
                    }))
                  }), _jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.SHUTTLE,
                      onClick: toggleDrawer
                    }, {
                      children: t('Park_sub4_title')
                    }))
                  }), _jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.OPERATION,
                      onClick: toggleDrawer
                    }, {
                      children: t('Park_sub5_title')
                    }))
                  })]
                }))]
              }))]
            }), _jsxs("li", {
              children: [_jsx("a", __assign({
                className: "link-gnb typo1" + (subMenu === "play" ? " sub-menu-open" : ""),
                onClick: function onClick() {
                  return changeSubMenu("play");
                }
              }, {
                children: "PLAY"
              })), _jsxs("div", __assign({
                className: "sub-menu",
                style: {
                  display: subMenu === "play" ? "block" : "none"
                }
              }, {
                children: [_jsx("a", __assign({
                  href: "/" + RoutePath.PLAY,
                  className: "depth-1",
                  onClick: toggleDrawer
                }, {
                  children: "PLAY INFORMATION"
                })), _jsxs("ul", __assign({
                  className: "depth-2-wrap"
                }, {
                  children: [_jsxs("li", {
                    children: [_jsx("a", __assign({
                      href: "/" + RoutePath.PLAY,
                      onClick: toggleDrawer
                    }, {
                      children: "OUTDOOR ACTIVITY"
                    })), _jsxs("ul", __assign({
                      className: "depth-3-wrap"
                    }, {
                      children: [_jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.OUTRACE981,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub1_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.OUTHEAL,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub2_title')
                        }))
                      })]
                    }))]
                  }), _jsxs("li", {
                    children: [_jsx("a", __assign({
                      href: "/" + RoutePath.INDOOR,
                      onClick: toggleDrawer
                    }, {
                      children: "INDOOR ACTIVITY"
                    })), _jsxs("ul", __assign({
                      className: "depth-3-wrap"
                    }, {
                      children: [_jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.INMETA,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub4_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.INPROARENA,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub32_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.INLAB,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub6_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.INCUBE,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub14_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.INRINGGO,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub5_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.INARENA,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub3_title')
                        }))
                      })]
                    }))]
                  }), _jsxs("li", {
                    children: [_jsx("a", __assign({
                      href: "/" + RoutePath.PLAYFLEX,
                      onClick: toggleDrawer
                    }, {
                      children: "FLEX & RELAX"
                    })), _jsxs("ul", __assign({
                      className: "depth-3-wrap"
                    }, {
                      children: [_jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.FLGARAGE,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub7_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.FLPHOTO,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub11_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.FLBRO,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub9_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.FLBOOSTER,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub12_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.FLONATZ,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub13_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.FLLOUNGE,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub10_title')
                        }))
                      }), _jsx("li", {
                        children: _jsx("a", __assign({
                          href: "/" + RoutePath.FLSPACE,
                          onClick: toggleDrawer
                        }, {
                          children: t('Play_sub8_title')
                        }))
                      })]
                    }))]
                  })]
                }))]
              }))]
            }), _jsxs("li", {
              children: [_jsx("a", __assign({
                className: "link-gnb typo1" + (subMenu === "race" ? " sub-menu-open" : ""),
                onClick: function onClick() {
                  return changeSubMenu("race");
                }
              }, {
                children: "RACE 981"
              })), _jsxs("div", __assign({
                className: "sub-menu",
                style: {
                  display: subMenu === "race" ? "block" : "none"
                }
              }, {
                children: [_jsx("a", __assign({
                  href: "/" + RoutePath.RACE981,
                  className: "depth-1",
                  onClick: toggleDrawer
                }, {
                  children: "RACE 981 INFORMATION"
                })), _jsxs("ul", __assign({
                  className: "depth-3-wrap"
                }, {
                  children: [_jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.GRAVITYRACER,
                      onClick: toggleDrawer
                    }, {
                      children: t('Race_sub1_title')
                    }))
                  }), _jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.LICENSE,
                      onClick: toggleDrawer
                    }, {
                      children: t('Race_sub2_title')
                    }))
                  }), _jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.BATTLE,
                      onClick: toggleDrawer
                    }, {
                      children: t('Race_sub3_title')
                    }))
                  }), _jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.RANKINGRULE,
                      onClick: toggleDrawer
                    }, {
                      children: t('Race_sub5_title')
                    }))
                  })]
                }))]
              }))]
            }), _jsxs("li", {
              children: [_jsx("a", __assign({
                className: "link-gnb typo1" + (subMenu === "groc" ? " sub-menu-open" : ""),
                onClick: function onClick() {
                  return changeSubMenu("groc");
                }
              }, {
                children: "GROC"
              })), _jsxs("div", __assign({
                className: "sub-menu",
                style: {
                  display: subMenu === "groc" ? "block" : "none"
                }
              }, {
                children: [_jsx("a", __assign({
                  href: "/" + RoutePath.GROC,
                  className: "depth-1",
                  onClick: toggleDrawer
                }, {
                  children: "GROC INFORMATION"
                })), _jsxs("ul", __assign({
                  className: "depth-3-wrap"
                }, {
                  children: [_jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.ABOUTGROC,
                      onClick: toggleDrawer
                    }, {
                      children: t('Groc_about')
                    }))
                  }), _jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.HALLOFFAME,
                      onClick: toggleDrawer
                    }, {
                      children: t('Groc_hall')
                    }))
                  })]
                }))]
              }))]
            }), _jsxs("li", {
              children: [_jsx("a", __assign({
                className: "link-gnb typo1" + (subMenu === "ticket" ? " sub-menu-open" : ""),
                onClick: function onClick() {
                  return changeSubMenu("ticket");
                }
              }, {
                children: "TICKET"
              })), _jsxs("div", __assign({
                className: "sub-menu",
                style: {
                  display: subMenu === "ticket" ? "block" : "none"
                }
              }, {
                children: [_jsx("a", __assign({
                  href: "/" + RoutePath.TICKET,
                  className: "depth-1",
                  onClick: toggleDrawer
                }, {
                  children: "TICKET INFORMATION"
                })), _jsxs("ul", __assign({
                  className: "depth-3-wrap"
                }, {
                  children: [_jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.GROUP,
                      onClick: toggleDrawer
                    }, {
                      children: t('Ticket_sub1_title')
                    }))
                  }), _jsx("li", {
                    children: _jsx("a", __assign({
                      href: "/" + RoutePath.TEAMROOM,
                      onClick: toggleDrawer
                    }, {
                      children: t('Ticket_sub2_title')
                    }))
                  })]
                }))]
              }))]
            })]
          })), _jsxs("div", __assign({
            className: "icon-list"
          }, {
            children: [_jsxs("ul", __assign({
              className: "sns-list"
            }, {
              children: [_jsx("li", {
                children: _jsx("a", __assign({
                  href: 'https://www.facebook.com/981park',
                  target: "_blank",
                  className: "sns"
                }, {
                  children: _jsx("img", {
                    src: "https://asset.981park.com/www/common/mweb/img/common/sns/m-00-common-menu-btn-01-facebook.svg",
                    alt: "facebook"
                  })
                }))
              }), _jsx("li", {
                children: _jsx("a", __assign({
                  href: 'https://www.instagram.com/9.81park/',
                  target: "_blank",
                  className: "sns"
                }, {
                  children: _jsx("img", {
                    src: "https://asset.981park.com/www/common/mweb/img/common/sns/m-00-common-menu-btn-02-instar.svg",
                    alt: "instar"
                  })
                }))
              }), _jsx("li", {
                children: _jsx("a", __assign({
                  href: 'https://www.youtube.com/channel/UCdPUWUu5ZOWegvaqqwj7hfg',
                  target: "_blank",
                  className: "sns"
                }, {
                  children: _jsx("img", {
                    src: "https://asset.981park.com/www/common/mweb/img/common/sns/m-00-common-menu-btn-03-youtube.svg",
                    alt: "youtube"
                  })
                }))
              }), _jsx("li", {
                children: _jsx("a", __assign({
                  href: 'https://blog.naver.com/981jeju',
                  target: "_blank",
                  className: "sns"
                }, {
                  children: _jsx("img", {
                    src: "https://asset.981park.com/www/common/mweb/img/common/sns/m-00-common-footer-btn-04-blog.svg",
                    alt: "blog"
                  })
                }))
              })]
            })), _jsxs("ul", __assign({
              className: "download-list"
            }, {
              children: [_jsx("li", {
                children: _jsx("a", __assign({
                  onClick: openWindow,
                  className: "download"
                }, {
                  children: _jsx("img", {
                    src: "https://asset.981park.com/www/common/mweb/img/common/sns/m-00-common-menu-btn-05-googleplay.svg",
                    alt: "Google play"
                  })
                }))
              }), _jsx("li", {
                children: _jsx("a", __assign({
                  onClick: openWindow,
                  className: "download"
                }, {
                  children: _jsx("img", {
                    src: "https://asset.981park.com/www/common/mweb/img/common/sns/m-00-common-menu-btn-06-apple.svg",
                    alt: "App Store"
                  })
                }))
              }), _jsx("li", {
                children: _jsx("a", __assign({
                  href: 'https://asset.981park.com/apk/app-apk-release.apk',
                  target: "_blank",
                  className: "download"
                }, {
                  children: _jsx("img", {
                    src: "https://asset.981park.com/www/common/mweb/img/common/sns/m-00-common-menu-btn-07-download.svg",
                    alt: "Download"
                  })
                }))
              })]
            }))]
          }))]
        }))
      }))]
    }))
  }));
};
export default withTranslation()(MobileHeader);