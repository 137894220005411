import React from "react";
import {withTranslation} from "react-i18next";
import Slider from "react-slick";
import RoutePath from "../../resource/RoutePath";
import { Link } from 'react-router-dom';
import { browserName, isIOS, isAndroid } from "react-device-detect";

class MHomeComp  extends React.Component {
	constructor(props) {
        super(props);
        this.park = React.createRef();
        this.play = React.createRef();
        this.race = React.createRef();
        this.groc = React.createRef();
        this.ticket = React.createRef();
		this.state = {
		};
    }

    /*openWindow = type => () => {
        switch (type) {
            case "android":
                window.open('https://race981.page.link/web_hero?utm_source=direct&utm_medium=button&utm_campaign=hero')
                break;
            case "ios":
                window.open('https://race981.page.link/web_hero?utm_source=direct&utm_medium=button&utm_campaign=hero')
                break;
        }
    }*/

    openWindow = () => {
        window.open('https://race981.page.link/web_hero?utm_source=direct&utm_medium=button&utm_campaign=hero');
    }
    
	render() {
        const {t} = this.props;

        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: 24
        };
        var linkDiv = <div className="app-button-pc">
                        <button type="button" className="btn btn-android" onClick={this.openWindow}><span className="blind">안드로이드 앱 다운로드</span></button>
                        <button type="button" className="btn btn-ios" onClick={this.openWindow}><span className="blind">아이폰 앱 다운로드</span></button>
                    </div>
        if (browserName === "Chrome WebView" && isAndroid) {
            linkDiv = null
        }

        if (browserName === "WebKit" && isIOS) {
            linkDiv = null
        }
		return (
			<div className="content-wrap main-content">
                <div className="visual-wrap">
                    <div className="video">
                        <video autoPlay muted loop playsInline className="video-autoplay" src="https://asset.981park.com/www/common/mweb/img/main/visual/981WEB.mp4" type="video/mp4"></video>	
                    </div>
                    {/* <div className="img">
                        <img src="https://asset.981park.com/www/common/mweb/img/main/visual/981MWEB.png" alt="981 park"/>
                    </div> */}
                    <div className="inner">
                        <div className="con">
                            <div className="text-wrap color1">
                                <p className="s-text typo1">ABOUT 9.81 PARK</p>
                                <p className="m-text">{t('HomeComp_title')}</p>
                                <p className="l-text typo1">JEJU NO.1 ATTRACTION, 981</p>
                                {linkDiv}
                            </div>
                        </div>	
                    </div>
                </div>
                <div className="card-list-wrap">
                    <div className="card-wrap">
                        <div className="inner">
                            <div className="con">
                                <div className="text-wrap">
                                    <p className="s-text typo1">PARK  INFORMATION</p>
                                    <p className="m-text">{t('HomeComp_park_title_1')} <br/> {t('HomeComp_park_title_2')}, <br/>{t('HomeComp_park_title_3')}</p>
                                </div>
                                <div className="card">
                                    <div className="card-inner">
                                        <img src="https://asset.981park.com/www/common/mweb/img/main/bg/img-1@2x.png" alt="9.81 파크"/>
                                        {/* <p className="text typo1 color1">AN ALL ACCESS <br/> EXPERIENCE ON ALL <br/> YOUR DEVICES.</p> */}
                                    </div>								
                                </div>
                                <div className="button-wrap" style={{cursor: "pointer"}}>
                                    <Link to={`/`+RoutePath.PARK} className="link-more"><span className="blind">more</span></Link>
                                </div>	
                            </div>
                        </div>					
                    </div>
                    <div className="card-wrap bg-color1">
                        <div className="inner">
                            <div className="con">
                                <div className="text-wrap">
                                    <p className="s-text typo1">PLAY INFORMATION</p>
                                    <p className="m-text">{t('HomeComp_play_title_1')} <br/> {t('HomeComp_play_title_2')} <br/>{t('HomeComp_play_title_3')}</p>
                                </div>						
                                <div className="button-wrap" style={{cursor: "pointer"}}>
                                    <Link to={`/`+RoutePath.INDOOR} className="link-more"><span className="blind">more</span></Link>
                                </div>	
                            </div>	
                        </div>
                        <div className="img-wrap">
                            <img src="https://asset.981park.com/www/common/mweb/img/main/bg/bg-1@2x.png" alt="PLAY INFORMATION"/>
                        </div>					
                    </div>
                    <div className="card-wrap">
                        <div className="inner">
                            <div className="con">
                                <div className="text-wrap">
                                    <p className="s-text typo1">RACE 981 INFORMATION</p>
                                    <p className="m-text">{t('HomeComp_race_title_1')} <br/>{t('HomeComp_race_title_2')} <br/><span className="ls-1">GRAVITY RACING</span></p>
                                </div>
                                <div className="button-wrap" style={{cursor: "pointer"}}>
                                    <Link to={`/`+RoutePath.RACE981} className="link-more"><span className="blind">more</span></Link>
                                </div>	
                            </div>	
                        </div>
                        <div className="img-wrap pt-230">
                            <img src="https://asset.981park.com/www/common/mweb/img/main/bg/bg-2@2x.png" alt="RACE 981 INFORMATIO"/>
                        </div>
                    </div>
                    <div className="card-wrap">
                        <div className="inner">
                            <div className="con">
                                <div className="text-wrap color1">
                                    <p className="s-text typo1">#GROC INFORMATION</p>
                                    <p className="m-text">{t('HomeComp_groc_title_1')}, <br/>{t('HomeComp_groc_title_2')} <br/><span className="ls-1"></span></p>
                                </div>
                                <div className="button-wrap" style={{cursor: "pointer"}}>
                                    <Link to={`/`+RoutePath.GROC} className="link-more"><span className="blind">more</span></Link>
                                </div>	
                            </div>	
                        </div>
                        <div className="img-wrap">
                            <img src="https://asset.981park.com/www/common/mweb/img/main/bg/bg-3@2x.png" alt="GROC INFORMATION"/>
                        </div>
                    </div>
                </div>
                <div className="ticket-list-wrap">
                    <div className="inner">
                        <div className="con">
                            <div className="text-wrap">
                                <p className="s-text typo1">TICKET INFORMATION</p>
                                <p className="m-text">{t('HomeComp_ticket_title_1')}</p>
                            </div>
                        </div>	
                        <div className="ticket-wrap">
                            <div className="ticket-list">
                                <Slider ref={c => (this.slider = c)} {...settings}>
                                    <div className="ticket t-case1">
                                        <div className="ticket-inner">
                                            <div className="label-txt"><span>{t('HomeComp_ticket_sub1_1')}</span></div>
                                            <Link to={`/`+RoutePath.TICKET+"#rec"} className="link-more"><span className="blind">more</span></Link>
                                            <p className="text">{t('HomeComp_ticket_sub1_2')} <br/> {t('HomeComp_ticket_sub1_3')} <br/> {t('HomeComp_ticket_sub1_4')}</p>
                                        </div>								
                                    </div>
                                    <div className="ticket t-case2">
                                        <div className="ticket-inner">
                                            <div className="label-txt"><span>{t('HomeComp_ticket_sub2_1')}</span></div>
                                            <Link to={`/`+RoutePath.TICKET+"#sale"} className="link-more"><span className="blind">more</span></Link>
                                            <p className="text">{t('HomeComp_ticket_sub2_2')} <br/> {t('HomeComp_ticket_sub2_3')} </p>
                                        </div>								
                                    </div>
                                    <div className="ticket t-case3">
                                        <div className="ticket-inner">
                                            <div className="label-txt"><span>{t('HomeComp_ticket_sub3_1')}</span></div>
                                            <Link to={`/`+RoutePath.TICKET+"#group"} className="link-more"><span className="blind">more</span></Link>
                                            <p className="text">{t('HomeComp_ticket_sub3_2')} <br/> {t('HomeComp_ticket_sub3_3')} <br/> {t('HomeComp_ticket_sub3_4')}</p>
                                        </div>							
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>				
                </div>			
            </div>
		);
	}
}

export default withTranslation()(MHomeComp);