import React from "react";
import {withTranslation} from "react-i18next";
import i18next from "../../resource/i18n";

class GroupComp  extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			
		};
	}

	componentDidMount() {
    }

	

	render() {
		const {t} = this.props;
		let rLink = "https://docs.google.com/forms/d/e/1FAIpQLSdytO-oLRpFeMrLTQ_XGfo3Ea1faOzXRaV25_cOZE1o0E-CEQ/viewform?usp=sf_link"
		if (i18next.language === "en" ) {
			rLink = "https://docs.google.com/forms/d/e/1FAIpQLSeMnEp8ZXUSvK7EVHHj5pAc4Q3rRoZ18U09tbFc0tDvKTwfvw/viewform"
		}
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/web/img/sub/ticket/slide-group-reservation1.png" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">GROUP RESERVATION</p>
									<p className="m-text">{t('Ticket_sub_c9_2')}</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list">
							<div className="list-con">
								<p className="lg-ttl">{t('Group_sub_c1')}</p>
								<div className="img">
									<img src="https://asset.981park.com/www/common/web/img/sub/ticket/img-group-reservation1.png" alt="단체예약"/>
								</div>
							</div>	
							
							<div className="list-con">
								<p className="lg-ttl">{t('Group_sub_c2')}</p>
								<div className="list-txt">						
									<ul>
										<li>
											<p className="txt">
											{t('Group_sub_c3')} <br/>
												<span className="hyphen">{t('Group_sub_c3_1')}</span> <br/>
												<span className="hyphen">{t('Group_sub_c4')}{t('Group_sub_c4_1')}</span> <br/>
												<span className="hyphen">{t('Group_sub_c5')} <a href={rLink} target="_blank" className="link">{t('Group_sub_c5_1')}</a></span>
											</p>
										</li>
										<li><p className="txt">{t('Group_sub_c6')}</p></li>
										<li><p className="txt">{t('Group_sub_c7')}</p></li>
									</ul>
									<p className="txt ps-txt">
										<span>{t('Group_sub_c8_1')}</span><br/>
										<span>{t('Group_sub_c8_2')}</span><br/>
										<span>{t('Group_sub_c8_3')}</span><br/>
										<span>{t('Group_sub_c8_4')}</span>
									</p>
								</div>
							</div>					
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(GroupComp);