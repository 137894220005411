var LocalStorage = /** @class */function () {
  function LocalStorage() {}
  LocalStorage.setItem = function (key, item) {
    if (typeof window !== "undefined") {
      localStorage.setItem(key, item);
    }
  };
  LocalStorage.getItem = function (key) {
    if (typeof window !== "undefined") {
      return localStorage.getItem(key);
    }
    return null;
  };
  LocalStorage.removeItem = function (key) {
    if (typeof window !== "undefined") {
      localStorage.removeItem(key);
    }
  };
  return LocalStorage;
}();
export default LocalStorage;