import React from "react";
import {withTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import RoutePath from '../../resource/RoutePath'
import { findTimeString } from "../../common/Util";

class MInMetaComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
		};
	}
	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/mweb/img/sub/play/slide-meta-9811.png" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
								<p className="s-text typo1">META 981</p>
								<p className="m-text">{t('InMetaComp_title_1_1')} <br/> {t('InMetaComp_title_1_2')} <br/> {t('InMetaComp_title_1_3')}</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list">
							<div className="list-con">
								<p className="lg-ttl">{t('InMetaComp_title_tag')}</p>
								<div className="img">
									<img src="https://asset.981park.com/www/common/mweb/img/sub/play/img-meta-9811.png" alt="meta-981"/>
								</div>
								<div className="txt-wrap">
									<div className="txt">
										{t('InMetaComp_title_contents')}
									</div>
								</div>
							</div>	
							
							<div className="list-con">
								<p className="lg-ttl">{t('InMetaComp_c_title_2')}</p>
								<div className="list-txt">						
									<ul>
										<li>
											<p className="txt">
                                                {t('InMetaComp_c_sub_2_1')}
											</p>
										</li>
									</ul>
								</div>
								<div className="button-wrap">
									<Link to={`/`+RoutePath.ACTIVITY} style={{cursor: "pointer"}} className="link-detail-more">{t('InMetaComp_c_sub_2_2')}</Link>
								</div>
							</div>	
							
							<div className="list-con">
								<p className="lg-ttl">{t('InMetaComp_c_title_3')}</p>
								<div className="list-txt">						
									<ul>
										<li>
											<p className="txt">
                                                {t('InMetaComp_c_sub_3_1')}
											</p>
										</li>
									</ul>
								</div>
								<div className="button-wrap">
									<Link to={`/`+RoutePath.RULES+"#vr"} style={{cursor: "pointer"}} className="link-detail-more">{t('InMetaComp_c_sub_3_2')}</Link>
								</div>
							</div>	
							
							<div className="list-con">
								<p className="lg-ttl">{t('OperationComp_c_title_4')}</p>
								<div className="button-wrap">
									<Link to={`/`+RoutePath.OPERATION} style={{cursor: "pointer"}} className="link-detail-more">{t('OperationComp_c_title_4_2')}</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MInMetaComp);