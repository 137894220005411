import React from "react";
import {withTranslation} from "react-i18next";
import RoutePath from "../../resource/RoutePath";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { DevServer, RealServer } from "../MainComponent";
import i18next from "i18next";
import axios from 'axios';
import { browserName, isIOS, isAndroid } from "react-device-detect";

class MTicketComp  extends React.Component {
	constructor(props) {
		super(props);
		this.rec = React.createRef();
        this.sale = React.createRef();
        this.group = React.createRef();
		this.state = {
			ticketData: null
		};
	}

	requestTicketData = async () => {
		var server = RealServer
        if (window.location.hostname === "localhost" || window.location.hostname === "dev-home.981park.net") {
            server = DevServer
        }
		axios({
			url:`${server}/customer/data/ticket/recommend`,
			method: 'get',
		  }).then(response => {
            this.setState({
                ticketData: response.data
            })
		  })
	}

	changeCoinDP(num) {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
	}

	componentDidMount() {
		let _this = this;
		if (window.location.hash !== "") {
			let hash = window.location.hash.replace("#", "")
			let hashResult = hash.split('?')[0]
			let target = undefined;
			switch (hashResult) {
				case "rec":
					target = _this.rec.current;
					break;
				case "recmdticket":
					target = _this.rec.current;
					break;
				case "sale":
					target = _this.sale.current;
					break;
				case "group":
					target = _this.group.current;
					break;
				default :
					break;

			}
			if(target === undefined) {
				window.scrollTo(0, 0);
			} else {
				if (hashResult === "sale") {
					setTimeout(() => {
						let yPos = this.getParentsOffsetTop(target);
						window.scrollTo({
							top: yPos + 700,
							behavior: 'smooth'
						});
					}, 100);
				} else {
					setTimeout(() => {
						let yPos = this.getParentsOffsetTop(target);
						window.scrollTo({
							top: yPos > 80 ? yPos - 80 : yPos,
							behavior: 'smooth'
						});
					}, 100);
				}
			}
		} else {
			window.scrollTo(0, 0);
		}
		_this.requestTicketData();
	}

	getParentsOffsetTop(el) {
		let parentsOffsetTop = 0;
		if(el.offsetParent){
			parentsOffsetTop = this.getParentsOffsetTop(el.offsetParent);
		}
		return el.offsetTop + parentsOffsetTop;
	}

	showPopup = () => () => {
		// this.props.showPopup();
		window.open("https://race981.page.link/MAIN", "_blank")
	}

	makeTicket() {
		var divs = this.state.ticketData.map(el => {
			return  <div className="ticket t-case10 ticket-no-hover">
						<div className="ticket-inner">
							<div className="label-txt"><span>{el.type}</span></div>
							<div className="price-wrap">
								<p className="text">{el.name.split('\\n')[0]} <br/> {el.name.split('\\n')[1]}</p>
								<div className="price-list-wrap">
									<div className="price-list">
										<div className="price-ttl">1인</div>
										<div className="price normal-price"><span className="num">{this.changeCoinDP(el.RecommededTicketList[0].price)}</span></div>
										<div className="price sale-price"><span className="num">{this.changeCoinDP(el.RecommededTicketList[0].down_price)}</span></div>
									</div>
									<div className="price-list">
										<div className="price-ttl">아이와 함께 2인</div>
										<div className="price normal-price"><span className="num">{this.changeCoinDP(el.RecommededTicketList[1].price)}</span></div>
										<div className="price sale-price"><span className="num">{this.changeCoinDP(el.RecommededTicketList[1].down_price)}</span></div>
									</div>
								</div>
							</div>
						</div>															
					</div>
		})								
		return divs
	}
	
	render() {
		const {t} = this.props;
		const settings = {
            dots: false,
            infinite: false,
            speed: 500,
			slidesToShow: 1,
			arrows: false,
            slidesToScroll: 1,
			centerMode: true,
            centerPadding: 24
		};
		var linkDiv = <div className="qr-wrap">
						<button type="button" onClick={this.showPopup()} className="btn-qr">{t('Ticket_sub_c2')}</button>
					</div>
		if (browserName === "Chrome WebView" && isAndroid) {
			linkDiv = null
		}

		if (browserName === "WebKit" && isIOS) {
			linkDiv = null
		}
		var ticketDivs = []
		ticketDivs.push(<div className="ticket t-case10 ticket-no-hover">
							<div className="ticket-inner">
								<div className="label-txt"><span>{t('Ticket_word_27')}</span></div>
								<div className="price-wrap">
									<p className="text">{t('Ticket_word_4')}</p>
									<div className="price-list-wrap">
										<div className="price-list">
											<div className="price-ttl">{t('Ticket_word_17')}</div>
											<div className="price normal-price"><span className="num">{t('Ticket_word_19')}</span></div>
											<div className="price sale-price"><span className="num">{t('Ticket_word_20')}</span></div>
										</div>
										<div className="price-list">
											<div className="price-ttl">{t('Ticket_word_18')}</div>
											<div className="price normal-price"><span className="num">{t('Ticket_word_21')}</span></div>
											<div className="price sale-price"><span className="num">{t('Ticket_word_22')}</span></div>
										</div>
									</div>
								</div>
							</div>															
						</div>)
		ticketDivs.push(<div className="ticket t-case10 ticket-no-hover">
							<div className="ticket-inner">
								<div className="label-txt"><span>{t('Ticket_word_27')}</span></div>
								<div className="price-wrap">
									<p className="text">{t('Ticket_word_3')}</p>
									<div className="price-list-wrap">
										<div className="price-list">
											<div className="price-ttl">{t('Ticket_word_17')}</div>
											<div className="price normal-price"><span className="num">{t('Ticket_word_23')}</span></div>
											<div className="price sale-price"><span className="num">{t('Ticket_word_24')}</span></div>
										</div>
										<div className="price-list">
											<div className="price-ttl">{t('Ticket_word_18')}</div>
											<div className="price normal-price"><span className="num">{t('Ticket_word_25')}</span></div>
											<div className="price sale-price"><span className="num">{t('Ticket_word_26')}</span></div>
										</div>
									</div>
								</div>
							</div>															
						</div>)
						
						var ticket1 = null;
						if (i18next.language === "ko") {
							ticket1 = <p className="text s58">{t('Ticket_word_29') + ", "} <br/> {t('Ticket_word_30')} <br/> <span className="ps-txt">{t('Ticket_word_8')} <br/> {t('Ticket_word_28')}</span></p>
						} else {
							ticket1 = <p className="text s58">{t('Ticket_word_29') + ", "} <br/> {t('Ticket_word_30')} <br/> {t('Ticket_word_30_1')} <br/> <span className="ps-txt">{t('Ticket_word_8')} <br/> {t('Ticket_word_28')}</span></p>
						}
						var ticket2 = null;
						if (i18next.language === "ko") {
							ticket2 = <p className="text">{t('Ticket_word_10')} <span className="ps-txt">{t('Ticket_word_11')} <br/> {t('Ticket_word_12')}</span></p>
						} else {
							ticket2 = <p className="text">{t('Ticket_word_10')} <br/> <span className="ps-txt">{t('Ticket_word_11')} <br/> {t('Ticket_word_12')}</span></p>
						}
						var ticket3 = null; //eslint-disable-line no-unused-vars
						if (i18next.language === "ko") {
							ticket3 = <p className="text">{t('Ticket_word_14')} <span className="ps-txt">{t('Ticket_word_15')} <br/> {t('Ticket_word_16')}</span></p>
						} else {
							ticket3 = <p className="text">{t('Ticket_word_14')} <br/> <span className="ps-txt">{t('Ticket_word_15')} <br/> {t('Ticket_word_16')}</span></p>
						}
		return (
			<div className="content-wrap sub-content">
				<div className="visual-wrap visual-type1">			
					<div className="inner">
						<div className="con">
							<div className="img">
								<img src="https://asset.981park.com/www/common/mweb/img/sub/ticket/img-ticket1.png" alt="ticket"/>
							</div>
							<div className="text-wrap color1">
								<p className="s-text typo1">TICKET INFORMATION</p>
								<p className="m-text">{t('Ticket_sub_c1')}</p>
								{linkDiv}
							</div>
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">				
						<div className="list" ref={this.rec}>	
							<div className="list-con">
								<div className="ttl-wrap" >
									<p className="s-ttl typo1">RECOMMENDED TICKETS</p>
									<p className="m-ttl">{t('Ticket_sub_c3')} <br/> {t('Ticket_sub_c3_1')}</p>
								</div>
								<div className="ticket-list-wrap">
									<div className="ticket-wrap">
										<div className="ticket-list ticket-type-list">												
											{ticketDivs}
										</div>
									</div>				
								</div>
								
							</div>	
							<div className="list-con">
								<p className="lg-ttl">{t('Ticket_sub_c4')}</p>
								<div className="ticket-list-wrap m-ticket-slider">
									<div className="ticket-wrap">								
										<div className="ticket-list" ref={this.sale}>	
											<Slider ref={c => (this.slider = c)} {...settings}>
												<div className="ticket t-case7 ticket-no-hover">
													<div className="ticket-inner">
														<div className="label-txt"><span>{t('Ticket_word_13')}</span></div>
														{ticket1}
													</div>															
												</div>
												<div className="ticket t-case8 ticket-no-hover">
													<div className="ticket-inner">
														<div className="label-txt"><span>{t('Ticket_word_9')}</span></div>
														{ticket2}
													</div>															
												</div>
											</Slider>
										</div>
									</div>
								</div>
								<div className="notice-wrap">
									<p className="ttl">{t('Ticket_sub_c5')}</p>	
									<div className="txt">
										<p>{t('Ticket_sub_c6')}</p>
										<p>{t('Ticket_sub_c7')}</p>
										<p>{t('Ticket_sub_c8')}</p>
									</div>
									<div ref={this.group}/>
								</div>				
							</div>
											
							<div className="list-con">
								<p className="lg-ttl">{t('Ticket_sub_c9')}</p>
								<div className="ticket-list-wrap m-ticket-slider">
									<div className="ticket-wrap">
										<div className="ticket-list">
											<Slider ref={c => (this.slider = c)} {...settings}>
												<div className="ticket t-case3">
													<div className="ticket-inner" style={{cursor: "pointer"}}>
														<div className="label-txt"><span>{t('Ticket_sub_c10')}/{t('Ticket_sub_c13')}</span></div>
														<Link to={`/`+RoutePath.GROUP} className="link-more"><span className="blind">more</span></Link>
														<p className="text">{t('Ticket_sub_c10')} <br/> {t('Ticket_sub_c11')}</p>
													</div>															
												</div>
												<div className="ticket t-case6">
													<div className="ticket-inner" style={{cursor: "pointer"}}>
														<div className="label-txt"><span>{t('Ticket_sub_c10')}/{t('Ticket_sub_c13')}</span></div>
														<Link to={`/`+RoutePath.TEAMROOM} className="link-more"><span className="blind">more</span></Link>
														<p className="text">{t('Ticket_sub_c12')} <br/> {t('Ticket_sub_c14')}</p>
													</div>															
												</div>
											</Slider>					
										</div>
									</div>				
								</div>			
							</div>					
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MTicketComp);