import React from "react";
import {withTranslation} from "react-i18next";
import Slider from "react-slick";
import RoutePath from "../../resource/RoutePath";
import { Link } from 'react-router-dom';
import i18next from "../../resource/i18n";

class HomeComp  extends React.Component {
	constructor(props) {
        super(props);
        this.park = React.createRef();
        this.play = React.createRef();
        this.race = React.createRef();
        this.groc = React.createRef();
        this.ticket = React.createRef();
		this.state = {
            mp4Loading: false
		};
    }
    
    scrollContent = idx => () => {
        switch (idx) {
            case 0:
                this.park.current.scrollIntoView({ behavior: 'smooth'});
                break;
            case 1:
                this.play.current.scrollIntoView({ behavior: 'smooth'});
                break;
            case 2:
                this.race.current.scrollIntoView({ behavior: 'smooth'});
                break;
            case 3:
                this.groc.current.scrollIntoView({ behavior: 'smooth'});
                break;
            case 4:
                this.ticket.current.scrollIntoView({ behavior: 'smooth'});
                break;
        }        
    };

    nextTicket = () => {
        this.slider.slickNext();
    }

    prevTicket = () => {
        this.slider.slickPrev();
    }

    linkTo  = path => () => {
        this.props.menuHandler(path);
    }

    /*openWindow = type => () => {
        switch (type) {
            case "android":
                window.open('https://race981.page.link/web_hero?utm_source=direct&utm_medium=button&utm_campaign=hero')
                break;
            case "ios":
                window.open('https://race981.page.link/web_hero?utm_source=direct&utm_medium=button&utm_campaign=hero')
                break;
        }
    }*/

    openWindow = () => {
        window.open('https://race981.page.link/web_hero?utm_source=direct&utm_medium=button&utm_campaign=hero');
    }

	render() {
        const {t} = this.props;

        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1
        };
		return (
			<div className="content-wrap main-content">
                <div className="visual-wrap">
                    <div className="video">
                        <video autoPlay muted loop playsInline src="https://asset.981park.com/www/common/web/img/main/visual/981WEB.mp4" onLoadStart={() => {
                                this.setState({ mp4Loading: true });
                            }}
                            onLoadedData={() => {
                                this.setState({ mp4Loading: false });
                            }}
                        ></video>	
                    </div>
                    <div className="inner">
                        <div className="con">
                            <div className="text-wrap color1">
                                <p className="s-text typo1">ABOUT 9.81 PARK</p>
                                <p className="m-text">{t('HomeComp_title')}</p>
                                <p className="l-text typo1">JEJU NO.1 ATTRACTION, 981</p>
                                <div className="app-button-pc">
                                    <button type="button" className="btn btn-android" onClick={this.openWindow}><span className="blind">{t('HomeComp_android_download')}</span></button>
                                    <button type="button" className="btn btn-ios" onClick={this.openWindow}><span className="blind">{t('HomeComp_ios_download')}</span></button>
                                </div>
                            </div>
        
                            <div className="button-wrap" onClick={this.scrollContent(0)} style={{cursor: "pointer"}}>
                                <a onClick={this.scrollContent(0)} className="btn-bottom typo1 color1">PARK INFORMATION</a>
                            </div>	
                        </div>	
                    </div>
                </div>
                <div className="card-list-wrap">
                    <div className="card-wrap" ref={this.park}>
                        <div className="inner">
                            <div className="con">
                                <div className="text-wrap">
                                    <p className="s-text typo1">PARK  INFORMATION</p>
                                    <p className="m-text">{t('HomeComp_park_title_1')} <br/> {t('HomeComp_park_title_2')}, <br/>{t('HomeComp_park_title_3')}</p>
                                </div>
                                <div className="card">
                                    <div className="card-inner">
                                        <img src="https://asset.981park.com/www/common/web/img/main/bg/img-1.png" alt="9.81 파크" className="img img-1"/>
                                        {/* <p className="text typo1 color1">AN ALL ACCESS <br/> EXPERIENCE ON ALL <br/> YOUR DEVICES.</p> */}
                                        {/* <button type="button" className="btn-play play"><span className="blind">play</span></button> */}
                                    </div>								
                                </div>
                                <div className="button-wrap" style={{cursor: "pointer"}}>
                                    <Link to={`/`+RoutePath.PARK} onClick={this.linkTo(RoutePath.PARK)} className="link-more"><span className="blind">more</span></Link>
                                    <a onClick={this.scrollContent(1)} className="btn-bottom typo1 color2">PLAY INFORMATION</a>
                                </div>	
                            </div>
        
                            <div className="line-wrap">
                                <div className="line line1"><img src="/common/web/img/main/bg/line-1.svg" alt="line"/></div>
                            </div>	
                        </div>					
                    </div>
                    <div className="card-wrap bg-color1">
                        <div className="inner">
                            <div className="con" ref={this.play}>
                                <div className="text-wrap">
                                    <p className="s-text typo1">PLAY  INFORMATION</p>
                                    <p className="m-text">{t('HomeComp_play_title_1')} <br/> {t('HomeComp_play_title_2')} <br/>{t('HomeComp_play_title_3')}</p>
                                </div>
                                <div className="img-wrap">
                                    <img src="https://asset.981park.com/www/common/web/img/main/bg/basketball.png" alt="basketball" className="img img-basketball"/>
                                    <img src="https://asset.981park.com/www/common/web/img/main/bg/galaxy.png" alt="galaxy" className="img img-galaxy"/>
                                    <img src="https://asset.981park.com/www/common/web/img/main/bg/ringggo-1.png" alt="ringggo" className="img img-ringggo1"/>
                                    <img src="https://asset.981park.com/www/common/web/img/main/bg/ringggo-2.png" alt="ringggo" className="img img-ringggo2"/>
                                </div>
                                <div className="button-wrap" style={{cursor: "pointer"}}>
                                    <Link to={`/`+RoutePath.INDOOR} onClick={this.linkTo(RoutePath.PLAY)} className="link-more"><span className="blind">more</span></Link>
                                    <a onClick={this.scrollContent(2)} className="btn-bottom typo1 color1">RACE 981 INFORMATION</a>
                                </div>	
                            </div>	
                            <div className="line-wrap">
                                <div className="line line2"><img src="https://asset.981park.com/www/common/web/img/main/bg/line-2.svg" alt="line"/></div>
                                <div className="line line3"><img src="https://asset.981park.com/www/common/web/img/main/bg/line-3.svg" alt="line"/></div>
                                <div className="line line4"><img src="https://asset.981park.com/www/common/web/img/main/bg/line-4.svg" alt="line"/></div>
                            </div>
                        </div>					
                    </div>
                    <div className="card-wrap">
                        <div className="inner">
                            <div className="con" ref={this.race}>
                                <div className="text-wrap">
                                    <p className="s-text typo1">RACE 981  INFORMATION</p>
                                    <p className="m-text">{t('HomeComp_race_title_1')} <br/>{t('HomeComp_race_title_2')} <br/><span className="ls-1">GRAVITY RACING</span></p>
                                </div>
                                <div className="img-wrap">
                                    <img src="https://asset.981park.com/www/common/web/img/main/bg/app-display-1.png" alt="app-display" className="img img-app-display1"/>
                                    <img src="https://asset.981park.com/www/common/web/img/main/bg/app-display-2.png" alt="app-display" className="img img-app-display2"/>
                                    <img src="https://asset.981park.com/www/common/web/img/main/bg/app-display-3.png" alt="app-display" className="img img-app-display3"/>
                                </div>
                                <div className="button-wrap" style={{cursor: "pointer"}}>
                                    <Link to={`/`+RoutePath.RACE981} onClick={this.linkTo(RoutePath.RACE981)} className="link-more"><span className="blind">more</span></Link>
                                    {/* <a className="link-more"><span className="blind">more</span></a> */}
                                    <a onClick={this.scrollContent(3)} className="btn-bottom typo1 color1">#GROC INFORMATION</a>
                                </div>	
                            </div>	
                        </div>
                    </div>
                    <div className="card-wrap bg-img1">
                        <div className="inner">
                            <div className="con" ref={this.groc}>
                                <div className="text-wrap color1">
                                    <p className="s-text typo1">#GROC  INFORMATION</p>
                                    <p className="m-text">{t('HomeComp_groc_title_1')}, <br/>{t('HomeComp_groc_title_2')}</p>
                                </div>
        
                                <div className="button-wrap" style={{cursor: "pointer"}}>
                                    <Link to={`/`+RoutePath.GROC} onClick={this.linkTo(RoutePath.GROC)} className="link-more"><span className="blind">more</span></Link>
                                    {/* <a className="link-more"><span className="blind">more</span></a> */}
                                    <a onClick={this.scrollContent(4)} className="btn-bottom typo1 color1">TICKET INFORMATION</a>
                                </div>	
                            </div>	
                        </div>
                    </div>
                </div>
                <div className="ticket-list-wrap">
                    <div className="inner">
                        <div className="con" ref={this.ticket}>
                            <div className="text-wrap">
                                <p className="s-text typo1">TICKET INFORMATION</p>
                                <p className="m-text">{t('HomeComp_ticket_title_1')}</p>
                            </div>
                        </div>	
                        <div className="ticket-wrap">
                            <div className="ticket-list">
                                <Slider ref={c => (this.slider = c)} {...settings}>
                                    <div className="ticket t-case1">
                                        <div className="ticket-inner">
                                            <div className="label-txt"><span>{t('HomeComp_ticket_sub1_1')}</span></div>
                                            <Link to={`/`+RoutePath.TICKET+"#rec"} onClick={this.linkTo(RoutePath.TICKET)} className="link-more"><span className="blind">more</span></Link>
                                            <p className="text">{t('HomeComp_ticket_sub1_2')} <br/> {t('HomeComp_ticket_sub1_3')} <br/> {t('HomeComp_ticket_sub1_4')}</p>
                                        </div>
                                    </div>
                                    <div className="ticket t-case2">
                                        <div className="ticket-inner">
                                            <div className="label-txt"><span>{t('HomeComp_ticket_sub2_1')}</span></div>
                                            <Link to={`/`+RoutePath.TICKET+"#sale"} onClick={this.linkTo(RoutePath.TICKET)} className="link-more"><span className="blind">more</span></Link>
                                            <p className="text">{t('HomeComp_ticket_sub2_2')} <br/> {t('HomeComp_ticket_sub2_3')} </p>
                                        </div>
                                    </div>
                                    <div className="ticket t-case3">
                                        <div className="ticket-inner">
                                            <div className="label-txt"><span>{t('HomeComp_ticket_sub3_1')}</span></div>
                                            <Link to={`/`+RoutePath.TICKET+"#group"} onClick={this.linkTo(RoutePath.TICKET)} className="link-more"><span className="blind">more</span></Link>
                                            <p className="text">{t('HomeComp_ticket_sub3_2')} <br/> {t('HomeComp_ticket_sub3_3')} <br/> {t('HomeComp_ticket_sub3_4')}</p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>				
                </div>
            </div>
		);
	}
}

export default withTranslation()(HomeComp);