import React from "react";
import {withTranslation} from "react-i18next";
import RoutePath from '../../resource/RoutePath'
import { Link } from 'react-router-dom';

class MIndoorComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
		};
	}
	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-wrap">
					<div className="img">
						<img src="https://asset.981park.com/www/common/mweb/img/sub/play/play-bg2.jpg" alt="트렌디한 공간을 누비며 안에서 놀자! 실내 액티비티 981"/>
					</div>
					<div className="inner">
						<div className="con">
							<div className="text-wrap color1">
								<p className="s-text typo1">PLAY INFORMATION</p>
								<p className="m-text">
                                    {t('IndoorComp_title_1_m1')}<br/>
                                    {t('IndoorComp_title_1_m2')}<br/>
                                    {t('IndoorComp_title_2')}</p>
							</div>
						</div>	
					</div>
				</div>
				<div className="card-list-wrap">
					<div className="card-wrap">
						<div className="inner">
							<div className="con">
								<div className="card-list">
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-2-5.png" alt="9.81 파크" className="img img-1"/>
											<p className="text text-ps-0"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/img-logo-meta-981.svg" alt="meta-981" className="meta-981-logo"/></p>
											<div className="label-txt"><span>{t('IndoorComp_activity')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.INMETA} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-2-6.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/img-logo-proarena.svg" alt="proarena" className="proarena-logo"/></p>
											<div className="label-txt"><span>{t('IndoorComp_activity')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.INPROARENA} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-2-4.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/sports-lab-logo.svg" alt="sports-lab" className="sports-lab-logo"/></p>
											<div className="label-txt"><span>{t('IndoorComp_activity')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.INLAB} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-2-7.png" alt="9.81 파크" className="img img-1"/>
											<p className="text text-ps-0"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/cube-verse-logo.svg" alt="cube-verse" className="cube-verse-logo"/></p>
											<div className="label-txt"><span>{t('IndoorComp_activity')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.INCUBE} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-2-3.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/ringggo.svg" alt="ringggo" className="ringggo-logo"/></p>
											<div className="label-txt"><span>{t('IndoorComp_activity')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.INRINGGO} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-2-1.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/galaxy-arena-logo.svg" alt="galaxy-arena" className="galaxy-arena-logo"/></p>
											<div className="label-txt"><span>{t('IndoorComp_activity')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.INARENA} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>						
									</div>
								</div>
							</div>
						</div>					
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MIndoorComp);