import React from "react";
import {withTranslation} from "react-i18next";
import i18next from "../../resource/i18n";
import RoutePath from "../../resource/RoutePath";
import { Link } from 'react-router-dom';

class MLicenseComp  extends React.Component {
	constructor(props) {
        super(props);
		this.tabX = React.createRef();
		this.state = {
			tap: "Tester"
		};
	}

	componentDidMount() {
		this.scrollTab()
	}

    scrollTab() {
		if (window.location.hash !== "") {
			let hash = window.location.hash.replace("#", "")
			let hashResult = hash.split('?')[0]
            if (hashResult === "tabX") {
                this.tabX.current.scrollIntoView();
				this.setState({tap: "Master"})
            }
		} else {
			window.scrollTo(0, 0);
		}
	}

	handleImageLoaded() {
		this.scrollTab();
	  }

	selectTap = course => () => {
        switch (course) {
            case "Tester":
				if (this.state.tap !== "Tester") {
					this.setState({tap: "Tester"})
				}
                break;
            case "Beginner":
                if (this.state.tap !== "Beginner") {
					this.setState({tap: "Beginner"})
				}
                break;
            case "Veteran":
                if (this.state.tap !== "Veteran") {
					this.setState({tap: "Veteran"})
				}
				break;
			case "Master":
				if (this.state.tap !== "Master") {
					this.setState({tap: "Master"})
				}
				break;
			default :
				if (this.state.tap !== "Tester") {
					this.setState({tap: "Tester"})
				}
        }        
	};

	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/mweb/img/sub/race/slide-license-system1.png" onLoad={this.handleImageLoaded.bind(this)} alt="레이스 981을 게임처럼 즐겨보자!"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">LICENSE SYSTEM</p>
									<p className="m-text">{t('GR_title2')}</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						
						<div className="list list-type5">
							<div className="list-con">
								<p className="lg-ttl">{t('Lic_sub_title')}</p>
								<div className="txt-wrap">
									<div className="lg-txt">
										{t('Lic_sub_c1')}
									</div>
									<div className="txt ps-txt">
										{t('Lic_sub_c2')}
									</div>
									<div ref={this.tabX} />
								</div>
							</div>	
						</div>
						
						<div className="list list-type6">
							<div className="tab-wrap">
								<ul className={"tab-menu tab-menu-4 " + (i18next.language === "en" ? "en" : "")}>
									<li><button onClick={this.selectTap("Tester")} className={(this.state.tap === "Tester") ? "selected" : ""}>{t('Lic_tab1')}</button></li>
									<li><button onClick={this.selectTap("Beginner")} className={(this.state.tap === "Beginner") ? "selected" : ""}>{t('Lic_tab2')}</button></li>
									<li><button onClick={this.selectTap("Veteran")} className={(this.state.tap === "Veteran") ? "selected" : ""}>{t('Lic_tab3')}</button></li>
									<li><button onClick={this.selectTap("Master")} className={(this.state.tap === "Master") ? "selected" : ""}>{t('Lic_tab4')}</button></li>
								</ul>

								<div className="tab-con-wrap">
									<div className="tab-con" style={{display: (this.state.tap === "Tester") ? "" : "none"}}>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/mweb/img/sub/race/license-system-tab1.png" onLoad={this.handleImageLoaded.bind(this)} alt="9.81 파크"/>
											</div>									
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Lic_sub_title1')}</p>
												<ul>
													<li><p className="txt">{t('Lic_sub_title1_c1')}</p></li>
												</ul>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Lic_sub_title1_c2')}</p>					
												<ul>
													<li><p className="txt">{t('Lic_sub_title1_c3')}</p></li>
												</ul>
											</div>
										</div>
									</div>
									
									<div className="tab-con" style={{display: (this.state.tap === "Beginner") ? "" : "none"}}>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/mweb/img/sub/race/license-system-tab2.png" onLoad={this.handleImageLoaded.bind(this)} alt="9.81 파크"/>
											</div>									
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Lic_sub_title2')}</p>					
												<ul>
													<li><p className="txt">{t('Lic_sub_title2_c1')}</p></li>
												</ul>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Lic_sub_title2_c2')}</p>
												<ul>
													<li><p className="txt">{t('Lic_sub_title2_c3')}</p></li>
												</ul>
											</div>
										</div>
										<div className="list-con">
											<div className="list-txt list-txt-type1">	
												<p className="ttl">{t('Lic_sub_title2_c4')}</p>
										<ul>
											<li><p className="txt">{t('Lic_sub_title2_c5')}</p></li>
										</ul>
									</div>
								</div>
							</div>
							
							<div className="tab-con" style={{display: (this.state.tap === "Veteran") ? "" : "none"}}>
								<div className="con">
									<div className="img">
										<img src="https://asset.981park.com/www/common/mweb/img/sub/race/license-system-tab3.png" onLoad={this.handleImageLoaded.bind(this)} alt="9.81 파크"/>
									</div>									
								</div>
								<div className="list-con">
									<div className="list-txt list-txt-type1">	
										<p className="ttl">{t('Lic_sub_title3')}</p>
										<ul>
											<li><p className="txt">{t('Lic_sub_title3_c1')}</p></li>
										</ul>
									</div>
								</div>
								<div className="list-con">
									<div className="list-txt list-txt-type1">	
										<p className="ttl">{t('Lic_sub_title3_c2')}</p>					
										<ul>
											<li><p className="txt">{t('Lic_sub_title3_c3')}</p></li>
										</ul>
									</div>
								</div>
								<div className="list-con">
									<div className="list-txt list-txt-type1">	
										<p className="ttl">{t('Lic_sub_title3_c4')}</p>
										<ul>
											<li><p className="txt">{t('Lic_sub_title3_c5')}</p></li>
										</ul>
									</div>
								</div>
							</div>
							
							<div className="tab-con" style={{display: (this.state.tap === "Master") ? "" : "none"}}>
								<div className="con">
									<div className="img">
										<img src="https://asset.981park.com/www/common/mweb/img/sub/race/license-system-tab4.png" onLoad={this.handleImageLoaded.bind(this)} alt="9.81 파크"/>
									</div>									
								</div>
								<div className="list-con">
									<div className="list-txt list-txt-type1">	
										<p className="ttl">{t('Lic_sub_title4')}</p>
										<ul>
											<li><p className="txt">{t('Lic_sub_title4_c1')} <br/> {t('Lic_sub_title4_c1_1')}</p></li>
										</ul>
									</div>
								</div>
								<div className="list-con">
									<div className="list-txt list-txt-type1">	
										<p className="ttl">{t('Lic_sub_title4_c2')}</p>
										<ul>
											<li><p className="txt">{t('Lic_sub_title4_c3')}</p></li>
											<li><p className="txt">{t('Lic_sub_title4_c3_1')}</p></li>
										</ul>
									</div>
								</div>
								<div className="list-con">
									<div className="list-txt list-txt-type1">	
										<p className="ttl">{t('Lic_sub_title4_c4')}</p>					
										<ul>
											<li><p className="txt">{t('Lic_sub_title4_c5')}</p></li>
											<li><p className="txt">{t('Lic_sub_title4_c5_1')}</p></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
		);
	}
}
export default withTranslation()(MLicenseComp);