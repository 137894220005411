import React from "react";
import {withTranslation} from "react-i18next";
import RoutePath from "../../resource/RoutePath";
import { Link } from 'react-router-dom';
import i18next from "i18next";

class RaceComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
		};
	}
	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-wrap">
					<div className="img">
						<img src="https://asset.981park.com/www/common/web/img/sub/race/race-bg1.png" alt="중력가속도(9.81m/s²)로 스피드를 즐기는 GRAVITY RACING"/>
					</div>
					<div className="inner">
						<div className="con">
							<div className="text-wrap color1">
								<p className="s-text typo1">ABOUT RACE981</p>
								<p className="m-text">{t('Park_title_1')}</p>
								<p className="l-text typo1">{t('Park_title_2')}</p>
							</div>
						</div>	
					</div>
				</div>
				<div className="card-list-wrap">
					<div className="card-wrap">
						<div className="inner">
							<div className="con">
								<div className="text-wrap">
									<p className="s-text typo1">RACE981 INFORMATION</p>
									<p className="m-text">{t('Play_sub1_title')} <br/> {t('Park_sub1_title2')}</p>
								</div>
								<div className="card-list">
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/race/card-1-1.png" alt="9.81 파크" className="img img-1"/>
											<p className="text typo1 color1">GRAVITY <br/> RACER</p>
											<div className="label-txt"><span>{t('Race_sub1_title')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.GRAVITYRACER} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>						
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/race/card-1-2.png" alt="9.81 파크" className="img img-1"/>
											<p className="text typo1 color1">LICENSE <br/> SYSTEM</p>
											<div className="label-txt"><span>{t('Race_sub2_title')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.LICENSE} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/race/card-1-3.png" alt="9.81 파크" className="img img-1"/>
											<p className="text typo1 color1">BATTLE</p>
											<div className="label-txt"><span>{t('Race_sub3_title')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.BATTLE} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/race/card-1-5.png" alt="9.81 파크" className="img img-1"/>
											<p className="text typo1 color1">RACING <br/> RULES</p>
											<div className="label-txt"><span>{t('Race_sub5_title')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.RANKINGRULE} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
								</div>
							</div>
						</div>					
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(RaceComp);