var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { withTranslation, useTranslation } from "react-i18next";
import RoutePath from '../constant/route';
import i18next from "../i18n";
import './index.scss';
var DesktopHeader = function DesktopHeader() {
  var _a = useState(""),
    showMenu = _a[0],
    setShowMenu = _a[1];
  var t = useTranslation().t;
  var changeLang = function changeLang(lang) {
    i18next.changeLanguage(lang);
    location.reload();
  };
  var hideSubMenu = function hideSubMenu() {
    setShowMenu("");
  };
  var changeMenu = function changeMenu(type) {
    setShowMenu(type);
  };
  var moveToBoard = function moveToBoard() {
    if (location.hostname.includes('localhost')) {
      window.location.href = 'http://localhost:3000/board';
      return;
    }
    window.location.href = "/".concat(RoutePath.BOARD);
  };
  return _jsx("header", __assign({
    className: "desktop-header"
  }, {
    children: _jsxs("div", __assign({
      className: "head-wrap"
    }, {
      children: [_jsx("h1", __assign({
        className: "logo"
      }, {
        children: _jsx("a", __assign({
          href: "/" + RoutePath.HOME
        }, {
          children: _jsx("img", {
            src: "https://asset.981park.com/www/common/web/img/common/logo/981-park.svg",
            alt: "981 PARK"
          })
        }))
      })), _jsx("nav", __assign({
        className: "nav-center"
      }, {
        children: _jsxs("ul", __assign({
          className: "gnb"
        }, {
          children: [_jsxs("li", {
            children: [_jsx("a", __assign({
              href: "/" + RoutePath.PARK,
              className: "link-gnb typo1",
              onMouseOver: function onMouseOver() {
                return changeMenu("park");
              }
            }, {
              children: "PARK"
            })), _jsx("div", __assign({
              className: "sub-menu",
              style: {
                display: showMenu === "park" ? "block" : "none"
              }
            }, {
              children: _jsxs("ul", __assign({
                className: "depth-3-wrap"
              }, {
                children: [_jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.BOARD,
                    onClick: moveToBoard
                  }, {
                    children: t('Park_sub0_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.ACTIVITY,
                    onClick: hideSubMenu
                  }, {
                    children: t('Park_sub1_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.PARKFLEX,
                    onClick: hideSubMenu
                  }, {
                    children: t('Park_sub2_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.CONVENIENT,
                    onClick: hideSubMenu
                  }, {
                    children: t('Park_sub3_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.SHUTTLE,
                    onClick: hideSubMenu
                  }, {
                    children: t('Park_sub4_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.OPERATION,
                    onClick: hideSubMenu
                  }, {
                    children: t('Park_sub5_title')
                  }))
                })]
              }))
            }))]
          }), _jsxs("li", {
            children: [_jsx("a", __assign({
              href: "/" + RoutePath.PLAY,
              className: "link-gnb typo1",
              onMouseOver: function onMouseOver() {
                return changeMenu("play");
              }
            }, {
              children: "PLAY"
            })), _jsx("div", __assign({
              className: "sub-menu",
              style: {
                display: showMenu === "play" ? "block" : "none"
              }
            }, {
              children: _jsxs("ul", __assign({
                className: "depth-2-wrap"
              }, {
                children: [_jsxs("li", {
                  children: [_jsx("a", __assign({
                    href: "/" + RoutePath.PLAY
                  }, {
                    children: "OUTDOOR ACTIVITY"
                  })), _jsxs("ul", __assign({
                    className: "depth-3-wrap"
                  }, {
                    children: [_jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.OUTRACE981,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub1_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.OUTHEAL,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub2_title')
                      }))
                    })]
                  }))]
                }), _jsxs("li", {
                  children: [_jsx("a", __assign({
                    href: "/" + RoutePath.INDOOR
                  }, {
                    children: "INDOOR ACTIVITY"
                  })), _jsxs("ul", __assign({
                    className: "depth-3-wrap"
                  }, {
                    children: [_jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INMETA,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub4_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INPROARENA,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub32_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INLAB,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub6_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INCUBE,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub14_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INRINGGO,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub5_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INARENA,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub3_title')
                      }))
                    })]
                  }))]
                }), _jsxs("li", {
                  children: [_jsx("a", __assign({
                    href: "/" + RoutePath.PLAYFLEX
                  }, {
                    children: "FLEX & RELAX"
                  })), _jsxs("ul", __assign({
                    className: "depth-3-wrap"
                  }, {
                    children: [_jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLGARAGE,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub7_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLPHOTO,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub11_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLBRO,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub9_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLBOOSTER,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub12_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLONATZ,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub13_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLLOUNGE,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub10_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLSPACE,
                        onClick: hideSubMenu
                      }, {
                        children: t('Play_sub8_title')
                      }))
                    })]
                  }))]
                })]
              }))
            }))]
          }), _jsxs("li", {
            children: [_jsx("a", __assign({
              href: "/" + RoutePath.RACE981,
              className: "link-gnb typo1",
              onMouseOver: function onMouseOver() {
                return changeMenu("race");
              }
            }, {
              children: "RACE 981"
            })), _jsx("div", __assign({
              className: "sub-menu",
              style: {
                display: showMenu === "race" ? "block" : "none"
              }
            }, {
              children: _jsxs("ul", __assign({
                className: "depth-3-wrap"
              }, {
                children: [_jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.GRAVITYRACER,
                    onClick: hideSubMenu
                  }, {
                    children: t('Race_sub1_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.LICENSE,
                    onClick: hideSubMenu
                  }, {
                    children: t('Race_sub2_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.BATTLE,
                    onClick: hideSubMenu
                  }, {
                    children: t('Race_sub3_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.RANKINGRULE,
                    onClick: hideSubMenu
                  }, {
                    children: t('Race_sub5_title')
                  }))
                })]
              }))
            }))]
          }), _jsxs("li", {
            children: [_jsx("a", __assign({
              href: "/" + RoutePath.GROC,
              className: "link-gnb typo1",
              onMouseOver: function onMouseOver() {
                return changeMenu("groc");
              }
            }, {
              children: "GROC"
            })), _jsx("div", __assign({
              className: "sub-menu",
              style: {
                display: showMenu === "groc" ? "block" : "none"
              }
            }, {
              children: _jsxs("ul", __assign({
                className: "depth-3-wrap"
              }, {
                children: [_jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.ABOUTGROC,
                    onClick: hideSubMenu
                  }, {
                    children: t('Groc_about')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.HALLOFFAME,
                    onClick: hideSubMenu
                  }, {
                    children: t('Groc_hall')
                  }))
                })]
              }))
            }))]
          }), _jsxs("li", {
            children: [_jsx("a", __assign({
              href: "/" + RoutePath.TICKET,
              className: "link-gnb typo1",
              onMouseOver: function onMouseOver() {
                return changeMenu("ticket");
              }
            }, {
              children: "TICKET"
            })), _jsx("div", __assign({
              className: "sub-menu",
              style: {
                display: showMenu === "ticket" ? "block" : "none"
              }
            }, {
              children: _jsxs("ul", __assign({
                className: "depth-3-wrap"
              }, {
                children: [_jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.GROUP,
                    onClick: hideSubMenu
                  }, {
                    children: t('Ticket_sub1_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.TEAMROOM,
                    onClick: hideSubMenu
                  }, {
                    children: t('Ticket_sub2_title')
                  }))
                })]
              }))
            }))]
          })]
        }))
      })), _jsxs("ul", __assign({
        className: "list-lang"
      }, {
        children: [_jsx("li", {
          children: _jsx("a", __assign({
            onClick: function onClick() {
              return changeLang("ko");
            },
            className: "link-lang" + (i18next.language === "ko" ? " selected" : ""),
            style: {
              cursor: "pointer"
            }
          }, {
            children: "KOR"
          }))
        }), _jsx("li", {
          children: _jsx("a", __assign({
            onClick: function onClick() {
              return changeLang("en");
            },
            className: "link-lang" + (i18next.language === "en" ? " selected" : ""),
            style: {
              cursor: "pointer"
            }
          }, {
            children: "ENG"
          }))
        })]
      }))]
    }))
  }));
};
export default withTranslation()(DesktopHeader);