import React from "react";
import {withTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import RoutePath from '../../resource/RoutePath'
import { findTimeSimpleString } from "../../common/Util";

class MFLGarageComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
		};
	}
	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/mweb/img/sub/play/slide-garage1.png" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
							</div>
						</div>			
					</div>
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">GARAGE 981</p>
									<p className="m-text">{t('FLGarageComp_title_1_m1')}<br/>{t('FLGarageComp_title_1_m2')}<br/>{t('FLGarageComp_title_1_m3')}<br/>{t('FLGarageComp_title_1_m4')}</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list">
							<div className="list-con">
								<p className="lg-ttl">{t('FLGarageComp_c_1_title')}</p>
								<div className="img">
									<img src="https://asset.981park.com/www/common/mweb/img/sub/play/img-garage1.png" alt="garage 981"/>
								</div>
								<div className="txt-wrap">
									<div className="txt">
                                        {t('FLGarageComp_c_1_sub_1')}
                                        {t('FLGarageComp_c_1_sub_2')}
									</div>
								</div>
								<div className="button-wrap">
									<a href="https://garage981.com/" className="link-detail-more" target="_blank" rel="noopener noreferrer">{t('FLGarageComp_c_1_sub_4')}</a>
									<Link to={`/`+RoutePath.PARKFLEX} style={{cursor: "pointer"}} className="link-detail-more">{t('FLGarageComp_c_1_sub_3')}</Link>
								</div>
							</div>	
							
							<div className="list-con">
								<p className="lg-ttl">{t('FLGarageComp_c_3_title')}</p>
								<div className="list-txt">						
									<ul>
										<li>
											<p className="txt">{t('FLGarageComp_c_3_sub_1')}</p>
										</li>
									</ul>
								</div>
							</div>	
							
							<div className="list-con">
								<p className="lg-ttl">{t('OperationComp_c_title_4')}</p>
								<div className="button-wrap">
									<Link to={`/`+RoutePath.OPERATION} style={{cursor: "pointer"}} className="link-detail-more">{t('OperationComp_c_title_4_2')}</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MFLGarageComp);