import React from 'react';

class MCNComponent  extends React.Component {
	constructor(props) {
        super(props);
    }

    componentDidMount() {
        require('../common/mweb/css/index.css');
    }

	render() {
        return (
            <div className="park-wrap park-cn">
                <div className="content-wrap sub-content">
                    <div className="visual-slide-wrap">
                        <div className="visual-slide-list">
                            <div className="slide">
                                <div className="img">
                                    <img src="https://asset.981park.com/www/common/mweb/img/cn/img_kv_race981.jpg" alt=""/>
                                </div>
                            </div>
                        </div>

                        <div className="visual-slide-text">
                            <div className="inner">
                                <div className="con">
                                    <div className="text-wrap color1">
                                        <p className="m-text typo1">9.81 PARK, 首次的重力赛车主题公园</p>
                                        <p className="s-text">
                                            9.81 PARK是一个赛车主题公园，您可以骑在特别设计的重力赛车上。<br/>
                                            重力赛车是使用重力作为唯一速度来源下坡的车辆。术语9.81表示重力加速度，9.81 m/s2。
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="detail-con-wrap">
                        <div className="inner">
                            <div className="list list-type3">
                                <div className="list-con">
                                    <p className="lg-ttl">开园时间 <span className="lg-txt">9:20 ~ 18:00</span></p>
                                    <div className="txt-wrap">
                                        <div className="txt">
                                            9.81 PARK全年营业，无休息日。营业时间可能因季节而异.<br/>
                                            每个设施的营业时间如下：
                                        </div>
                                        <div className="txt ps-txt">• 由于天气原因，RACE981可能会暂时暂停或提前关闭。</div>
                                    </div>
                                    <ul>
                                        <li>
                                            <p className="ttl">RACE 981</p>
                                            <p className="txt">9:40pm ~ 5:40pm <br/> (last check-in at 5:20pm)</p>
                                        </li>
                                        <li>
                                            <p className="ttl">Sport Lab</p>
                                            <p className="txt">9:20am ~ 6:00pm</p>
                                        </li>
                                        <li>
                                            <p className="ttl">META 981</p>
                                            <p className="txt">9:20am ~ 6:00pm</p>
                                        </li>
                                        <li>
                                            <p className="ttl">RINGGGO</p>
                                            <p className="txt">9:20am ~ 6:00pm</p>
                                        </li>
                                        <li>
                                            <p className="ttl">ARENA</p>
                                            <p className="txt">9:20am ~ 6:00pm</p>
                                        </li>
                                        <li>
                                            <p className="ttl">HEAL SWING</p>
                                            <p className="txt">9:20am ~ 6:00pm</p>
                                        </li>
                                        <li>
                                            <p className="ttl">SPACE ZERO</p>
                                            <p className="txt">12:00pm ~ 6:00pm <br/> (last order at 5:00pm)</p>
                                        </li>
                                        <li>
                                            <p className="ttl">GARAGE 981</p>
                                            <p className="txt">9:20am ~ 6:00pm</p>
                                        </li>
                                        <li>
                                            <p className="ttl">BROCCOLLEGE</p>
                                            <p className="txt">9:20am ~ 6:00pm</p>
                                        </li>
                                        <li>
                                            <p className="ttl">Lounge X (cafe)</p>
                                            <p className="txt">9:20am ~ 6:00pm <br/> (last order at 5:40pm)</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="list list-type6">
                                <div className="list-con">
                                    <p className="lg-ttl">地图与旅行信息</p>
                                    <div className="map-wrap">
                                        <div className="map">
                                            <img src="https://asset.981park.com/www/common/mweb/img/cn/map.png" alt=""/>
                                        </div>
                                        <div className="inner">
                                            <div className="con">
                                                <div className="text-wrap color1">
                                                    <p className="s-text typo1">地址</p>
                                                    <p className="m-text">济州特别自治道济州市涯月邑千德路<br/> 880-24, 9.81 파크</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="list-con">
                                    <div className="list-txt list-txt-type1">
                                        <p className="ttl">乘坐汽车</p>
                                        <ul>
                                            <li>
                                                <p className="txt">
                                                    沿着平和路（路号码1135）行驶，在於音一交叉路口出去。在出去后右转，<br/>
                                                    沿着千德路行驶约一公里，直到看到入口处的标志。距机场或中文观光园区大约三十分钟路程。
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="list-con">
                                    <div className="list-txt list-txt-type1">
                                        <p className="ttl">乘坐公交车</p>
                                        <ul>
                                            <li>
                                                <p className="txt">
                                                    如果您来自济州岛（或西归浦市），请乘坐蓝色巴士282,251,252,253,254到西归浦（或济州岛）<br/>
                                                    方向，在国学院站下车。沿着千德路步行约15分钟到达公园入口。
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="list-con">
                                    <div className="list-txt list-txt-type1">
                                        <p className="ttl">从机场</p>
                                        <ul>
                                            <li>
                                                <p className="txt">
                                                    有9.81班车往返机场。请检查当前可用的两条服务路线的时间表。
                                                </p>
                                                <div className="txt-table">
                                                    <ul>
                                                        <li>
                                                            <p className="th">机场班车：停车场的B1和B2区</p>
                                                        </li>
                                                        <li>
                                                            <p className="th">新济州路线 (需要30分钟。)</p>
                                                        </li>
                                                        <li>
                                                            <p className="th">从机场</p>
                                                            <p className="td">10:00, 12:00, 13:00, 15:00, 16:00</p>
                                                        </li>
                                                        <li>
                                                            <p className="th">从9.81PARK</p>
                                                            <p className="td">9:10, 11:00, 12:00, 14:00, 15:00, 16:40</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="list">
                                <div className="list-con">
                                    <p className="lg-ttl">RACE 981</p>
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/cn/img-race-9811.png" alt="RACE 981"/>
                                    </div>
                                    <div className="txt-wrap">
                                        <div className="txt">
                                            9.81PARK的主要游乐设施包括三种重力赛车和四种不同的课程 <br/><br/>
                                            越过终点线后，您可以将手从方向盘上移开，
                                            并可以拍摄自拍并检查您的记录与9.81手机软件因为智能的自动驾驶GR将带您回到起跑线。
                                            对于masters，GR-X驾驶员，我们为您提供了一个更特殊的经历。
                                        </div>
                                    </div>
                                    <div className="list-txt list-txt-type2">
                                        <ul>
                                            <li>
                                                <div className="label">
                                                    <p className="txt">GR-E</p>
                                                    <p className="img"><img src="https://asset.981park.com/www/common/mweb/img/cn/img_race981_010_GRE_20190220-uai-720x900.png" alt="GR-E"/></p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <p className="txt">
                                                        单座GR，专为有资格享受RACE 981的每个人设计。它可以达到40公里/小时的速度，体验更快，
                                                        因为车身距离地面只有5厘米。您可以选择在两个不同的课程中享受GR，课程1和2。
                                                    </p>
                                                    <div className="img-wrap">
                                                        <p className="img">
                                                            <img src="https://asset.981park.com/www/common/mweb/img/cn/E1Course.png" alt=""/>
                                                        </p>
                                                        <p className="img">
                                                            <img src="https://asset.981park.com/www/common/mweb/img/cn/E2Course.png" alt=""/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="label">
                                                    <p className="txt">GR-D</p>
                                                    <p className="img"><img src="https://asset.981park.com/www/common/mweb/img/cn/img_race981_011_GRD_20190220-uai-720x900.png" alt="GR-D"/></p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <p className="txt">
                                                        特别设计的双座GR – 建议家庭访客与孩子和/或访客与您的重要其他人。它可以达到35公里/
                                                        小时的速度，后座的人不开车，并可以欣赏风景。 课程3适用于GR-D。
                                                    </p>
                                                    <div className="img-wrap">
                                                        <p className="img">
                                                            <img src="https://asset.981park.com/www/common/mweb/img/cn/DCourse_20190330.png" alt=""/>
                                                        </p>
                                                        <p className="img">
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="label">
                                                    <p className="txt">GR-X</p>
                                                    <p className="img"><img src="https://asset.981park.com/www/common/mweb/img/cn/img_race981_012_GRX_20190220-uai-720x900.png" alt="GR-X"/></p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <p className="txt">
                                                        最高精密度的GR，只允许有特殊master的驾驶员。
                                                        当你达到指定的单圈时间和最高速度时第二次或以后GR骑行的，将颁发许可证。
                                                        许可证有效期为一年，标准可能会根据season和游戏规则而有所变化。
                                                        GR-X具有独特的功能是所谓的助推器可以瞬间提高速度达5秒。使用助推器，这种GR可以达到60公里/
                                                        小时。在这个级别，您的驾驶能力非常敏锐，您可以在转弯时使用转向的细微差别，
                                                        因此我们允许您在预订时选择左或右轨道。
                                                    </p>
                                                    <div className="img-wrap">
                                                        <p className="img">
                                                            <img src="https://asset.981park.com/www/common/mweb/img/cn/XCourse_20190330.png" alt=""/>
                                                        </p>
                                                        <p className="img">
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="list">
                                <div className="list-con">
                                    <p className="lg-ttl">GARAGE 981</p>
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/cn/img-garage1.png" alt="GARAGE 981"/>
                                    </div>
                                    <div className="txt-wrap">
                                        <div className="txt">
                                            9.81PARK的品牌商店, 在这里您可以找到全系列的优质产品，<br/>
                                            这些产品全部特别挑选的来自世界各地或济州纪念品
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list">
                                <div className="list-con">
                                    <p className="lg-ttl">LAB 981</p>
                                    <div className="img">
                                        <img src="https://asset.981park.com/www/common/mweb/img/cn/img-sport-lab1.png" alt="LAB 981"/>
                                    </div>
                                    <div className="txt-wrap">
                                        <div className="txt">
                                            各种室内游乐设施的街机，包括篮球，棒球，保龄球，足球，传奇和更多<br/>
                                            <span className="ps-txt">VR（虚拟现实）RACING 也正在运行。</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="list list-type4">
                                <div className="list-con-wrap">
                                    <p className="lg-ttl">981 PARK 政策</p>
                                    <div className="list-con">
                                        <p className="ttl">取消预约</p>
                                        <div className="txt-wrap">
                                            <div className="txt">
                                                当天取消或更改预约是不可能的，且不予退还。 <br/>
                                                您可以直到预订前一天的午夜更改时间表，取消或退款。后乘车(票的使用)您不可以受部分取消和退票。
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-con">
                                        <p className="ttl">RACE981限制</p>
                                        <div className="txt-wrap">
                                            <div className="txt">
                                                你必须至少14岁，身高150厘米才能享受RACE 981作为驾驶员。GR-D后座的骑手必须至少3岁，身高100厘米。要享受GR-X，<br/>
                                                您必须年满18岁并且必须获得由9.81 PARK颁发的特殊的硕士执照。您无需眞驾驶执照即可驾驶任何GR。
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-con">
                                        <p className="ttl">RACE981着装要求</p>
                                        <div className="txt-wrap">
                                            <div className="txt">
                                                强力劝告要穿着舒适，穿着不穿露趾，也不穿高跟鞋的好步鞋。凉鞋，拖鞋和裙子可能妨碍安全驾驶。<br/>
                                                妨碍安全驾驶的着装和其他配件可能导致拒绝入场。
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-con">
                                        <p className="ttl">储物柜可用</p>
                                        <div className="txt-wrap">
                                            <div className="txt">
                                                请将贵重物品和其他配件（例如帽子，袋子，围巾等）放在位于主楼地下二层CENTRAL的储物柜中。请记住，储物柜不够大，不适合大旅行箱。
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}
export default MCNComponent;