import React from "react";
import {withTranslation} from "react-i18next";
import { findStartTimeString, findLastTimeString } from "../../common/Util";
import moment from "moment";
import axios from 'axios';
export const DevServer = "https://dev-apis.981park.net"
export const RealServer = "https://apis-jeju.981park.com"

class MOperationComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
			hoursData: null,
			current: 0
		};
	}

	requestHours = async () => {
        var server = RealServer
		if (window.location.hostname === "dev-home.981park.net") {
			server = DevServer
        }
		axios({
			url:`${server}/customer/data/park/opHoursByDate?searchDate=${moment().add({ day:this.state.current }).format("YYYY-MM-DD")}`,
			method: 'get',
		  }).then(response => {
            this.setState({
				hoursData: response.data
			})
		  })
    }

	componentDidMount() {
		this.requestHours();
    }

	preDay = () => {
		this.setState({
			current: (this.state.current - 1)
		}, this.requestHours)
	}

	nextDay = () => {
		this.setState({
			current: (this.state.current + 1)
		}, this.requestHours)
	}

	render() {
		const {t} = this.props;
		const opOrder = ["레이스 981",
		"메타 981",
		"프로아레나",
		"랩 981 스포츠랩",
		"큐브버스",
		"랩 981 링°°고",
		"아레나",
		"하늘그네",
		"GARAGE 981",
		"포토드링크",
		"브로콜리지",
		"부스터스테이션",
		"오나츠",
		"LOUNGE'X",
		"space. ZERO",
		"에너지스테이션",
		"부스터트레일러"]
		const opOrderTitle = [t('OperationComp_c_subtitle_01'),
		t('OperationComp_c_subtitle_02'),
		t('OperationComp_c_subtitle_051'),
		t('OperationComp_c_subtitle_04'),
		t('OperationComp_c_subtitle_16'),
		t('OperationComp_c_subtitle_03'),
		t('OperationComp_c_subtitle_05'),
		t('OperationComp_c_subtitle_06'),
		t('OperationComp_c_subtitle_08'),
		t('OperationComp_c_subtitle_13'),
		t('OperationComp_c_subtitle_09'),
		t('OperationComp_c_subtitle_15'),
		t('OperationComp_c_subtitle_14'),
		t('OperationComp_c_subtitle_11'),
		t('OperationComp_c_subtitle_07'),
		t('OperationComp_c_subtitle_12'),
		t('OperationComp_c_subtitle_17')]
		
		if ( this.state.hoursData !== null && this.state.hoursData !== undefined) {
			var startDivs = []
			var lastDivs = []
			var parkOpTime = this.state.hoursData.parkOpTime;
			var opTime = this.state.hoursData.placeOpTimes;
			var opTimeStr = "00:00 ~ 00:00";
			opTimeStr = parkOpTime.startTime + ` ~ ` + parkOpTime.endTime;
			for(var i=0; i<opOrder.length; i++) {
				var itemTime = "00:00 ~ 00:00";
				var litemTime = "00:00";
				itemTime = findStartTimeString(opTime,opOrder[i]);
				litemTime = findLastTimeString(opTime,opOrder[i]);
				if (itemTime !== "" && litemTime !== "") {
					startDivs.push(<li>
						<p className="txt">{opOrderTitle[i]}</p>
						<p className="txt">{itemTime}</p>
					</li>)
					lastDivs.push(<li>
						<p className="txt">{opOrderTitle[i]}</p>
						<p className="txt">{litemTime}</p>
					</li>)
				}
			}
		}

		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/mweb/img/sub/park/slide-operating-hours1.png" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">OPERATING HOURS</p>
									<p className="m-text">{t('OperationComp_title_1')}<br/> {t('OperationComp_title_2')} <br/> {t('OperationComp_title_3')} <br/> {t('OperationComp_title_4')}</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list list-type3">
							<div className="list-con">
								<p className="lg-ttl">{t('OperationComp_c_title')} <span className="lg-txt">{opTimeStr}</span></p>
								<div className="img">
									<img src="https://asset.981park.com/www/common/mweb/img/sub/park/img-operating-hours.png" alt="운영시간"/>
								</div>
							</div>

							<div className="list-con">
								<div className="date-wrap">
									<button onClick={this.preDay} className="btn btn-prev"><span className="blind">이전</span></button>
									<span className="num">{moment().add({ day:this.state.current }).format("YYYY-MM-DD")}</span>
									<button onClick={this.nextDay} className="btn btn-next"><span className="blind">다음</span></button>
								</div>
								<p className="lg-ttl">{t('OperationComp_m_1')}</p>
								<ul>
									<li>
										<p className="ttl">{t('OperationComp_m_2')}</p>
										<p className="ttl">{t('OperationComp_c_title')}</p>
									</li>
									{startDivs}
								</ul>
							</div>
							<div className="list-con">
								<p className="lg-ttl">{t('OperationComp_c_sub3')}</p>
								<ul>
									{lastDivs}
								</ul>
							</div>				
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MOperationComp);