import React from "react";
import {withTranslation} from "react-i18next";
import i18next from "../../resource/i18n";
import { Link } from 'react-router-dom';
import RoutePath from '../../resource/RoutePath'

class MFooterComp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentWillMount() {

	}

	changeLang = (event) => {
		if (event.target.value === "ko") {
			i18next.changeLanguage('ko');
		} else if (event.target.value === "en"){
			i18next.changeLanguage('en');
		} else {
			i18next.changeLanguage('ko');
		}
	};

	render() {
		const {t} = this.props;
		var topButton = (<div className="link-top-wrap">
			<button onclick="window.scrollTo(0,0);" className="link-top"><span className="blind">top</span></button>
		</div>)
		if (window.location.pathname === "/guide") {
			topButton = null
		}
		
		return (
			<>
				{topButton}
				<footer>
					<div className="footer-wrap">
						<div className="inner">
							<div className="info-wrap">
								<div className="info_policy">
									<ul className="policy-list">
										{/*<li><Link to={`/`+RoutePath.PRIVACY} style={{cursor: "pointer"}}>{t('FooterComp_pri')}</Link></li>*/}
										<li><a href={'https://app.catchsecu.com/document/P/24f7518177892ee'} target="_blank" rel="noopener noreferrer">{t('FooterComp_pri')}</a></li>
										<li><Link to={`/`+RoutePath.TERMS} style={{cursor: "pointer"}}>{t('FooterComp_terms')}</Link></li>
										<li><Link to={`/`+RoutePath.RULES} style={{cursor: "pointer"}}>{t('FooterComp_rules')}</Link></li>
									</ul>
									
									<div className="btn-list">
										<div className="select-box-wrap">
											<select name="" id="" onChange={this.changeLang}>
												<option value="ko">{t('FooterComp_lang_ko')}</option>
												<option value="en">{t('FooterComp_lang_en')}</option>
											</select>
										</div>
										{/* <a className="link-career"><span className="blind">WE ARE HIRING! 채용</span></a> */}
									</div>
								</div>
								<div className="info-company">
									<p className="text">
										<b>{t('FooterComp_seoul_company')}</b> <br/>
										{t('FooterComp_seoul_company_1')}<br/>
										{t('FooterComp_seoul_company_2')}<br/>
										{t('FooterComp_seoul_company_3_1')}<br/>
										{t('FooterComp_seoul_company_3_2')}
									</p>
									
									<p className="text">
										<b>{t('FooterComp_jeju_company')}</b> <br/>
										{t('FooterComp_jeju_company_1')}<br/>
										{t('FooterComp_jeju_company_2')}<br/>
										{t('FooterComp_jeju_company_3_1')}<br/>
										{t('FooterComp_jeju_company_3_2')}<br/>
										{t('FooterComp_jeju_company_4')}<br/>
									</p>
								</div>
								<div className="info-copyright">
									<div className="sns-list">
										<a href={'https://www.facebook.com/981park'} target="_blank" rel="noopener noreferrer" className="sns"><img src="https://asset.981park.com/www/common/mweb/img/common/sns/00-common-footer-btn-01-facebook.svg" alt="facebook"/></a>
										<a href={'https://www.instagram.com/9.81park/'} target="_blank" rel="noopener noreferrer" className="sns"><img src="https://asset.981park.com/www/common/mweb/img/common/sns/00-common-footer-btn-02-instar.svg" alt="instar"/></a>
										<a href={'https://www.youtube.com/channel/UCdPUWUu5ZOWegvaqqwj7hfg'} target="_blank" rel="noopener noreferrer" className="sns"><img src="https://asset.981park.com/www/common/mweb/img/common/sns/00-common-footer-btn-03-youtube.svg" alt="youtube"/></a>
										<a href={'https://blog.naver.com/981jeju'} target="_blank" rel="noopener noreferrer" className="sns"><img src="https://asset.981park.com/www/common/mweb/img/common/sns/00-common-footer-btn-04-blog.svg" alt="blog"/></a>
									</div>
									
									<div className="copyright">
										<p className="text">Copyright ⓒ MONOLITH &amp; MONOLITH JEJU PARK INC.All rights reserved.</p>
									</div>	
								</div>
							</div>							
						</div>
					</div>		
				</footer>
			</>
		);
	}
}
export default withTranslation()(MFooterComp);