import React from "react";
import {withTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import RoutePath from '../../resource/RoutePath'
import { findTimeSimpleString } from "../../common/Util";

class FLPhotoComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
		};
	}
	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/web/img/sub/play/slide-photodrink1.png" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">PHOTODRINK</p>
									<p className="m-text">{t('InPhotoComp_title_1')} <br/> {t('InPhotoComp_title_2') + " " + t('InPhotoComp_title_3')}</p>
								</div>
							</div>	
						</div>	
					</div>					
				</div>

				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list">
							<div className="list-con">
								<p className="lg-ttl">{t('InPhotoComp_title_tag')}</p>
								<div className="img">
									<img src="https://asset.981park.com/www/common/web/img/sub/play/img-photodrink1.png" alt="photodrink"/>
								</div>
								<div className="txt-wrap">
									<div className="txt">
										{t('InPhotoComp_title_contents_1')}<br/>
										{t('InPhotoComp_title_contents_2')}<br/>
										{t('InPhotoComp_title_contents_3')}<br/><br/>
										<b>{t('InPhotoComp_title_contents_3_1')}</b><br/>
										{t('InPhotoComp_title_contents_4')}<br/><br/>
										<b>{t('InPhotoComp_title_contents_3_2')}</b><br/>
										{t('InPhotoComp_title_contents_5')}
									</div>
								</div>
								<div className="button-wrap">
									<Link to={`/`+RoutePath.PARKFLEX} style={{cursor: "pointer"}} className="link-detail-more">{t('InPhotoComp_title_location')}</Link>
								</div>
							</div>
							
							<div className="list-con">
								<p className="lg-ttl">{t('InPhotoComp_title_price')}</p>
								<div className="list-txt">						
									<ul>
										<li><p className="txt">{t('InPhotoComp_title_contents_3_1')} {t('InPhotoComp_title_contents_3_1_1')} / {t('InPhotoComp_title_contents_3_2')}  {t('InPhotoComp_title_contents_3_1_1')}</p></li>
									</ul>
								</div>
							</div>				
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(FLPhotoComp);