import BattleComp from './BattleComp';
import GravityRacerComp from './GravityRacerComp';
import LicenseComp from './LicenseComp';
import RaceComp from './RaceComp';
import RankingComp from './RankingComp';
import RankingRuleComp from './RankingRuleComp';
import MBattleComp from './MBattleComp';
import MGravityRacerComp from './MGravityRacerComp';
import MLicenseComp from './MLicenseComp';
import MRaceComp from './MRaceComp';
import MRankingComp from './MRankingComp';
import MRankingRuleComp from './MRankingRuleComp';

export { BattleComp, GravityRacerComp, LicenseComp, RaceComp, RankingComp, RankingRuleComp, MBattleComp, MGravityRacerComp, MLicenseComp, MRaceComp, MRankingComp, MRankingRuleComp };