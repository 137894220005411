import React from "react";
import i18next from "i18next";
import {withTranslation} from "react-i18next";
import axios from 'axios';
import moment from "moment";
import busTime from '../../resource/BusTime.json'

class MShuttleComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
			selectTap: "free",
			timeList: [],
			current: 0
		};
	}

	requestTimeData = async () => {
		axios({
			url:`https://asset.981park.com/www/common/BusTime.json?trash=${Math.random()}`,
			method: 'get',
		  }).then(response => {
			this.busTime = response.data
			this.findTodayTime()
		  })
	}

	findTodayTime() {
        for (var i=0; i<busTime.length; i++) {
			var today = moment().add({ day:this.state.current }).format("YYYY-MM-DD")
			var startD = new Date(busTime[i].startDate)
			var endD = new Date(busTime[i].endDate)
			var todayD = new Date(today)
            if ((startD <= todayD) && (todayD <= endD)) {
                this.setState({
                    timeList: busTime[i].busTimes
                })
                return
            }
        }

		this.setState({
			timeList: busTime[busTime.length-1].busTimes
		})
    }

	preDay = () => {
		this.setState({
			current: (this.state.current - 1)
		}, this.findTodayTime)
	}

	nextDay = () => {
		this.setState({
			current: (this.state.current + 1)
		}, this.findTodayTime)
	}

	componentDidMount() {
		this.findTodayTime()
	}

	changeTap = type => () => {
		this.setState({selectTap: type});
	}
	
	render() {
		const {t} = this.props;
		var aSpans = [];
		var nSpans = [];
		var pSpans = [];
		var npSpans = [];
		let crewString = "";
		if (this.state.timeList.length !== 0) {
			let aStop = this.state.timeList.filter(time => time.from_stop === "A");
			let nStop = this.state.timeList.filter(time => time.from_stop === "N");
			let pStop = this.state.timeList.filter(time => time.from_stop === "P" && time.to_stop === "A");
			let pnStop = this.state.timeList.filter(time => time.from_stop === "P" && time.to_stop === "N");
			
			for (var i=0; i<aStop.length; i++) {
				if (i ===  aStop.length-1) {
					aSpans.push(<span>{aStop[i].start_time.substr(0,5)}</span>);
				} else {
					aSpans.push(<span>{aStop[i].start_time.substr(0,5)+`,`}</span>);
				}
			}
			for (var i=0; i<nStop.length; i++) {
				if (i ===  nStop.length-1) {
					nSpans.push(<span>{nStop[i].start_time.substr(0,5)}</span>);
				} else {
					nSpans.push(<span>{nStop[i].start_time.substr(0,5)+`,`}</span>);
				}
			}
			for (var i=0; i<pStop.length; i++) {
				if (i ===  pStop.length-1) {
					pSpans.push(<span>{pStop[i].start_time.substr(0,5)}</span>);
				} else {
					pSpans.push(<span>{pStop[i].start_time.substr(0,5)+`,`}</span>);
				}
			}
			for (var i=0; i<pnStop.length; i++) {
				if (i ===  pnStop.length-1) {
					npSpans.push(<span>{pnStop[i].start_time.substr(0,5)}</span>);
				} else {
					npSpans.push(<span>{pnStop[i].start_time.substr(0,5)+`,`}</span>);
				}
			}
			crewString = pStop[pStop.length-1].start_time.substr(0,5);
		}

		var busStop = null
		if (i18next.language === "en") {
			busStop = <li>
				<p className="ttl">BUS STOP</p>
				<p className="ttl">{t('ShuttleComp_19')}</p>
				<p className="txt line"><span>Bus No. 251, 252, 253, 254, 282</span></p>
			</li>
		} else {
			busStop = <li>
				<p className="ttl">{t('ShuttleComp_19')}</p>
				<p className="txt line"><span>Bus No. 251, 252, 253, 254, 282</span></p>
			</li>
		}

		var busStop2 = null
		if (i18next.language === "en") {
			busStop2 = <li>
				<p className="ttl">BUS STOP</p>
				<p className="ttl">{t('ShuttleComp_20')}</p>
				<p className="txt line"><span>Bus No. 251, 252, 253, 254, 282</span></p>
			</li>
		} else {
			busStop2 = <li>
				<p className="ttl">{t('ShuttleComp_20')}</p>
				<p className="txt line"><span>Bus No. 251, 252, 253, 254, 282</span></p>
			</li>
		}

		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/mweb/img/sub/park/slide-shuttle-bus1.png" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
							</div>
						</div>			
					</div>
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">SHUTTLE BUS</p>
									<p className="m-text">{t('ShuttleComp_title_2_m3')}<br/>{t('ShuttleComp_title_1_m1')}<br/>{t('ShuttleComp_title_1_m2')}</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list list-type2">
							<div className="tab-wrap">
								<ul className="tab-menu tab-menu-2">
									<li><a onClick={this.changeTap("free")} className={((this.state.selectTap === "free") ? "selected" : "")}><span className="m-txt">{t('ShuttleComp_13')}</span></a></li>
									<li><a onClick={this.changeTap("inner")} className={((this.state.selectTap === "inner") ? "selected" : "")}><span className="m-txt">{t('ShuttleComp_14')}</span></a></li>
								</ul>
								<div className="tab-con-wrap">
									<div className="tab-con" style={{display: (this.state.selectTap === "free" ? "" : "none")}}>
										<div className="con">
											<div className="img">
												<img src="https://asset.981park.com/www/common/mweb/img/sub/park/tab1.png" alt="9.81 파크"/>
												<div className="label-txt"><span>{t('ShuttleComp_15')}</span></div>
											</div>
											<div className="img">
												<img src={"https://asset.981park.com/www/common/mweb/img/sub/park/tab2" + (i18next.language === "en" ? "_en.png" : ".png")} alt="9.81 파크"/>
												<div className="label-txt"><span>{t('ShuttleComp_16')}</span></div>
											</div>
											<div className="list">
												<div className="date-wrap">
													<button onClick={this.preDay} className="btn btn-prev"><span className="blind">이전</span></button>
													<span className="num">{moment().add({ day:this.state.current }).format("YYYY-MM-DD")}</span>
													<button onClick={this.nextDay} className="btn btn-next"><span className="blind">다음</span></button>
												</div>
												<div className="list-con-wrap">
													<div className="list-con">
														<p className="lg-ttl">{t('ShuttleComp_c_1')}</p>
														<ul>
															<li>
																<p className="ttl">{t('ShuttleComp_c_3')}{t('ShuttleComp_c_3_1')} → {t('ShuttleComp_c_4')}</p>
																<p className="txt line">{aSpans}</p>
															</li>
															<li>
																<p className="ttl">{t('ShuttleComp_c_5')} → {t('ShuttleComp_c_4')}</p>
																<p className="txt line">{nSpans}</p>
															</li>
														</ul>
													</div>

													<div className="list-con">
														<p className="lg-ttl">{t('ShuttleComp_c_2')}</p>
														<ul>
															<li>
																<p className="ttl">{t('ShuttleComp_c_4')} → {t('ShuttleComp_c_3')}</p>
																<p className="txt line">{pSpans}</p>
															</li>
															<li>
																<p className="ttl">{t('ShuttleComp_c_5_1')} → {t('ShuttleComp_c_3')}</p>
																<p className="txt line">{npSpans}</p>
															</li>
														</ul>
													</div>
												</div>
												<div className="info-wrap">
													<p className="txt ps-txt">
														<span className="pt-10">{t('ShuttleComp_4')}</span>
														<span>{t('ShuttleComp_4_1')}</span>
														<span>{t('ShuttleComp_4_2')}</span>
														<span>{t('ShuttleComp_4_3')}</span>
														<span>{t('ShuttleComp_4_4')}</span>
														<span>{t('ShuttleComp_4_5')}</span>
														<span>{t('ShuttleComp_4_6')}</span>
														<span>{t('ShuttleComp_4_7')}</span>
														<span>{t('ShuttleComp_4_8')}</span>
													</p>
												</div>
											</div>
										</div>
									</div>

									<div className="tab-con" style={{display: (this.state.selectTap === "inner" ? "" : "none")}}>
										<div className="con">
											<div className="img">
												<img src={"https://asset.981park.com/www/common/mweb/img/sub/park/tab3" + (i18next.language === "en" ? "_en.png" : ".png")} alt="9.81 파크"/>
												<div className="label-txt"><span>{t('ShuttleComp_17')}</span></div>
											</div>

											<div className="list">
												<div className="list-con-wrap">
													<div className="ttl">{t('ShuttleComp_18')}</div>

													<div className="list-con">
														<p className="lg-ttl">{t('ShuttleComp_c_1')}</p>
														<ul>
															{busStop}
														</ul>
													</div>

													<div className="list-con">
														<p className="lg-ttl">{t('ShuttleComp_c_2')}</p>
														<ul>
															{busStop2}
														</ul>
													</div>
												</div>
												<div className="info-wrap">
													<p className="txt ps-txt">
														<span>· {t('ShuttleComp_21')}</span>
														<span>· {t('ShuttleComp_22')}  <br/> {t('ShuttleComp_22_1')} <br/>
														<a href="http://bus.jeju.go.kr/" target="_blank" className="link">{t('ShuttleComp_23')}</a>
													</span>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MShuttleComp);