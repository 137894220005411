import React from "react";
import {withTranslation} from "react-i18next";
import Slider from "react-slick"; //eslint-disable-line no-unused-vars

class MTermsComp  extends React.Component {
	constructor(props) {
        super(props);
	}

	render() {
		const {t} = this.props; //eslint-disable-line no-unused-vars
		return (
			<div className="content-wrap sub-content">
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list list-type4">
							<div className="list-ttl">
								<p className="s-ttl typo1">9.81 PARK</p>
								<p className="ttl">이용약관</p>
							</div>
							
							<ul className="list-link-wrap">
								<li><a href="#con1">제1조<span>정책의 적용</span></a></li>
								<li><a href="#con2">제2조<span>운영시간 및 입장제한</span></a></li>
								<li><a href="#con3">제3조<span>이용요금 및 탑승제한</span></a></li>
								<li><a href="#con4">제4조<span>취소·환불·변경</span></a></li>
								<li><a href="#con5">제5조<span>이용수칙</span></a></li>
								<li><a href="#con6">제6조<span>분실물처리</span></a></li>
								<li><a href="#con7">제7조<span>손해배상(사고시 처리)</span></a></li>
							</ul>
							
							<div id="con1" className="list-con">
								<p className="ttl">제1조 정책의 적용</p>
								<div className="txt-wrap">
									<div className="txt">
										본 운영정책은 모노리스제주파크㈜(이하 ‘회사’)가 9.81 파크 및 관련 앱서비스(이하 ‘서비스’)를 운영함에 있어 발생할 수 있는 문제상황에 대하여 일관성 있게 
										대처하기 위해 서비스의 운영 기준과 고객의 준수사항을 규정하였습니다. 운영정책을 지키지 않을 경우 불이익을 당할 수 있으니 주의하여 주시기 바랍니다. <br/><br/>
										본 운영정책에 명시되지 않은 사항은 관계법령 및 일반적인 사회통념에 의해 판단, 처리됩니다.
									</div>
								</div>
							</div>	
							
							<div id="con2" className="list-con">
								<p className="ttl">제2조 운영시간 및 입장제한</p>
								<div className="txt-wrap">
									<div className="txt">
										<ol>
											<li>운영시간은 별도로 게시하며, 회사의 정책에 따라 변경될 수 있습니다.</li>
											<li>고객의 편의와 안전을 위해 적정 인원 초과 시 파크 내 입장을 제한할 수 있으며, 반려동물은 입장이 제한됩니다.</li>
											<li>회사는 위험물(질)을 소지한 이용객의 입장을 제한할 수 있습니다. 이를 위해 필요하면 회사는 보안요원으로 하여금 이용객의 동의 하에 소지품을 확인할 수 있으며, 정당한 사유 없이 이를 거부하는 경우 회사는 해당 이용객에 대해 입장을 거부할 수 있습니다.</li>
											<li>파크내 시설물의 경우 운영시간에 차이가 있을 수 있으며, 제한인원 조기 마감시 티켓판매가 중단됩니다. 티켓판매여부는 9.81 PARK 앱 예약현황 또는 현장 발권 시 확인할 수 있습니다.</li>
											<li>운영 중 집중호우, 태풍, 낙뢰 등 기상악화 및 천재지변 등 불가항력적 상황 발생 시 고객의 안전을 위해 회사측의 판단에 따라 임시적으로 중단 또는 휴장 할 수 있습니다.</li>
											<li>상기 5항의 이유로 운영 중단 시, 예약 티켓은 잔여회차 및 잔여시간에 대한 금액만 환불되며  일부 티켓은 재탑승권이 지급됩니다.</li>
										</ol>
									</div>
								</div>
							</div>	
							
							<div id="con3" className="list-con">
								<p className="ttl">제3조 이용요금 및 탑승제한</p>
								<div className="txt-wrap">
									<div className="txt">
										<ol>
											<li>이용요금은 별도로 게시하며, 회사의 정책에 따라 변경될 수 있습니다.</li>
											<li>단체의 경우 별도의 요금 및 이용정책이 적용될 수 있으며, 해당요금은 별도로 게시합니다.</li>
											<li>제주도민 할인 등 우대요금은 회사측의 정책에 따라 변경될 수 있으며, 이용요금과 함께 게시합니다. 우대요금 적용 시, 티켓 발권 전 확인할 수 있는 증명을 제시하여야 하며, 증명 불가시에는 일반요금 적용을 받습니다.</li>
											<li>시설물(RACE 981, LAB 981 등) 이용 시 연령 및 신장제한, 음주·약물 불가, 질병·신체조건 제한 규정이 적용되니 티켓예약 및 발권 시 양지하시기 바랍니다.</li>
											<li>일부 시설은 이용 가능여부 확인을 위해 신분확인증을 요구할 수 있으며 거부할 경우 탑승이 제한될 수 있습니다.</li>
										</ol>
									</div>
								</div>
							</div>
							
							<div id="con4" className="list-con">
								<p className="ttl">제4조 취소·환불·변경</p>
								<div className="txt-wrap">
									<div className="txt">
										<ol>
											<li>탑승 당일 티켓의 취소 및 환불, 변경은 불가합니다.</li>
											<li>탑승 후 부분 취소 및 환불, 변경은 불가합니다.</li>
											<li>다회권은 운전자기준 1인 사용권으로 사용하지 않은 잔여탑승 부분을 타인에게 양도하거나 공유할 수 없습니다.</li>
											<li>제2조 5항과 같이 고객 안전을 위한 임시 운영중단/휴장 시에는 잔여회차 및 잔여시간에 대한 금액만 환불되며, 일부 티켓은 재탑승권이 지급됩니다.</li>
										</ol>
									</div>
								</div>
							</div>
							
							<div id="con5" className="list-con">
								<p className="ttl">제5조 이용수칙</p>
								<div className="txt-wrap">
									<div className="txt">
										9.81 PARK를 이용하는 고객들은 안전하고 즐거운 주행을 위해 다음과 같은 이용수칙을 준수해야 합니다.

										<p className="li-ttl">가. 9.81 PARK</p>
										<ul>
											<li>파크에서의 흡연은 지정된 흡연구역에서만 가능합니다. 흡연구역 외에서 흡연 시 회사는 고객을 제지할 수 있으며, 정당한 사유없이 응하지 않을 경우 퇴장을 요청할 수 있습니다.</li>
											<li>파크에서의 음주는 파크에 지정된 음식점 내부에서만 가능하며, 음주 후 RACE 981 탑승은 불가합니다. 음주 후 RACE 981 탑승 시도 시, 티켓은 환불없이 취소되고 2회이상 적발 시 1년간 이용이 제한됩니다.</li>
											<li>반려동물의 입장은 불가합니다.</li>
											<li>안전하고 깨끗한 환경을 위하여 다음 물품의 반입은 금지됩니다.</li>
											<li>드론, 무선조종 장난감 등 전동제품</li>
											<li>음식을 데우거나 조리하는 버너 등 위험물</li>
											<li>돗자리, 접이식 피크닉 의자, 텐트 등</li>
											<li>나이프(칼), 낫, 못, 각목, 폭발물, 총기류 등</li>
											<li>파크내 안전사고 예방을 위하여 다음 물품의 사용은 금지됩니다.</li>
											<li>스케이트보드, 인라인, 킥보드, 자전거 등</li>
											<li>고성방가, 폭력행사 등으로 다른 고객 또는 직원의 신체나 정신·재산상 피해를 야기하였거나 해가 될 우려가 있는 행동을 하는 고객은 입장을 제한할 수 있으며 환불 없이 강제 퇴장 조치될 수 있습니다.</li>
											<li>어떠한 경우에도 고객은 회사의 직원에게 폭력, 폭언, 성희롱 또는 성추행을 할 수 없습니다. 만약 회사로부터 제공받은 서비스에 관해 불만이 있거나 기타 사유가 있는 경우 회사의 고객상담실에 이를 고지해야 하며, 고지 받은 회사는 사실관계를 조사한 후, 해당 고객에게 성실히 답변할 것입니다.</li>
											<li>고객은 파크 내 시설 이용시 각 시설별 이용 및 안전 수칙을 반드시 준수할 의무가 있습니다. 수칙을 준수하지 않을 경우 이용이 제한될 수 있습니다.</li>
											<li>회원, 예약 등의 9.81 파크 앱 서비스 정책은 ‘서비스/전자상거래 이용약관’ 또는 ‘개인정보 처리방침’ 약관을 따릅니다.</li>
										</ul>
										
										<p className="li-ttl">나. RACE 981</p>
										<ul>
											<li>일반차량(GR-E, GR-D)의 운전자는 만 14세 이상이고 신장 150cm 이상, 190cm 미만이어야 합니다.</li>
											<li>2인승차량(GR-D)의 동승자는 신장 100cm 이상이어야 합니다.</li>
											<li>상급자차량(GR-X)의 탑승을 위해서는 만 19세 이상이고 신장 150cm 이상, 190cm미만이어야 하며 9.81 마스터라이센스가 있어야합니다. 마스터라이센스는 주행 기준 조건 달성 시 발급되며, 마스터라이센스가 있더라도 만 19세 미만의 고객은 상급자차량의 탑승이 불가합니다.</li>
											<li>고객의 안전을 위하여 임산부의 탑승은 금지됩니다</li>
											<li>음주 및 약물복용자는 탑승이 금지됩니다. 해당사항 적발 시, 티켓은 환불없이 취소되고 2회이상 적발 시 1년간 이용이 제한됩니다.</li>
											<li>안전한 주행을 위해서는 탑승자 전원이 근골격계질환, 심혈관계 질환 및 신경심리적 질환 등을 포함한 신체적·정신적 이상이 없는 건강한 상태여야 합니다.</li>
											<li>탑승을 위해서는 반드시 “앞뒤가 막힌 굽 없는 신발”을 착용해야 합니다. (예: 운동화) 슬리퍼 및 하이힐과 통굽 등은 브레이크 패달 사용에 문제가 되기에 착용할 수 없으며, 주행에 방해가 될 수 있는 모자, 머플러 및 액세서리의 착용을 금합니다.</li>
											<li>탑승자 전원은(운전자 및 동승자) 모두 허리와 등, 목을 지지할 수 있는 힘이 있어야 합니다.</li>
											<li>운전자는 운전시 경사와 급회전 코스의 빠른 움직임에 대비하기 위해 양 손을 정상적으로 사용하고 제동을 위해 오른발이 자유로워야 합니다.</li>
											<li>운전자는 안전을 위해 동반자의 도움 없이 차량으로 이동 및 운전이 가능해야 하며, 운전이 가능한 적정한 시력을 가지고 있어야 하고, 안전 요원의 지시를 들을 수 있어야 합니다.</li>
											<li>주행 및 회차 중에 셀카봉의 사용은 금지됩니다.</li>
											<li>구매하신 티켓에 표기된 유효시간 내에 입장 게이트에서 입장을 완료하여야 하며, 해당시간이 지나면 티켓은 자동으로 무효처리 됩니다.</li>
											<li>승차 및 하차시 안전요원의 지시에 따라야 하며, 트랙 내 모든 신호와 경고 표지판을 반드시 준수하여야 합니다.</li>
											<li>운전자 및 동승자 모두 하차할 때까지 안전벨트를 항상 착용하고 있어야 하며, 주행 및 자동회차 중에 절대 자리에서 일어나면 안됩니다.</li>
											<li>운전자의 경우 무동력 다운힐 주행 시, 양손은 항상 운전대(스티어링휠)를 잡고 있어야 하며 오른쪽 발은 브레이크 페달위에 위치해야 합니다.</li>
											<li>다운힐 주행 중 갑자기 트랙에서 멈춰서 있으면 뒤차량과의 충돌로 인한 사고가 발생할 수 있습니다. 불가피한 경우 차량을 트랙 가장자리로 붙여 세우고 안전요원을 호출해야 합니다.</li>
											<li>자동회차 시 운전자는 운전대에서 손을 떼고, 절대 무리하게 힘을 주어서는 안됩니다.</li>
											<li>주행 및 회차 중에 개인물품을 떨어뜨리지 않도록 주의하고, 트랙에 소지품을 떨어뜨렸을 경우 멈춰서거나 직접 줍지 말고 반드시 안전요원을 호출해야 합니다.</li>
											<li>상기 운행수칙을 준수하지 않거나 타인의 안전을 위협하는 고객의 경우, 회사는 해당 고객의 입장을 거부하고 환불없이 퇴장을 요구할 수 있습니다.</li>
											<li>9.81 Gravity Race of Champions(GROC)에 참가 신청한 고객은 대회 기간 중 촬영되는 사진 및 영상물에 대해 회사의 초상권 사용(촬영, 배포 판권 소유)에 동의하는 것으로 간주됩니다.</li>
										</ul>
									</div>
								</div>
							</div>
							
							<div id="con6" className="list-con">
								<p className="ttl">제6조 분실물 처리</p>
								<div className="txt-wrap">
									<div className="txt">
										<ol>
											<li>회사는 분실물 보관소를 운영하며, 분실물의 접수 및 인수인계 사항을 분실물 처리대장에 기록 유지하고, 물품의 인계를 위한 제반조치를 취합니다.</li>
											<li>회사는 유실물법령에 따라 습득된 물품을 7일동안 보관한 후, 소유자가 찾아가지 않을 경우 관할 경찰서에 신고 및 이관합니다.</li>
											<li>기타 분실물 처리에 관한 사항은 유실물 법령 규정에 따릅니다.</li>
										</ol>
									</div>
								</div>
							</div>
							
							<div id="con7" className="list-con">
								<p className="ttl">제7조 손해배상(사고시 처리)</p>
								<div className="txt-wrap">
									<div className="txt">
										<ol>
											<li>회사측의 과실로 인하여 고객에게 손해가 발생하였을 경우 회사는 배상의 의무를 집니다.</li>
											<li>제5조 이용수칙을 준수하지 않아 발생한 사고 및 그 외 고객의 귀책사유에 의해 발생한 사고에 대해서는 민법 기타 관련 법규상 손해배상 규정에 따릅니다.</li>
										</ol>
									</div>
								</div>
							</div>
							
							<div className="list-con">
								<p className="ttl">부칙</p>
								<div className="txt-wrap">
									<div className="txt">
										본 약관은 2020년 03월 27일부터 시행합니다.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MTermsComp);