import React from 'react';
import {withTranslation} from "react-i18next";
import i18next from "../../resource/i18n"; //eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'; //eslint-disable-line no-unused-vars
import RoutePath from '../../resource/RoutePath' //eslint-disable-line no-unused-vars

class Race981GuideComp  extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            step: 1
		};
    }

    chageStep = step => () => {
        if (this.state.step !== step) {
            this.setState({step: step})
            window.scrollTo(0,0)
        }
	};

	render() {
        const {t} = this.props; //eslint-disable-line no-unused-vars
		return (
			<>
                <div className="content-wrap hearing-impaired-content-wrap race-981">
                    <div className="visual-wrap">
                        <div className="img">
                            <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/bitmap.png" alt="race981"/>
                        </div>
                        <div className="inner">
                            <div className="con">
                                <div className="text-wrap color1">
                                    <p className="s-text typo1">RACE 981</p>
                                    <p className="m-text">온 몸으로 <br/> 바람을 즐겨라!</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="detail-con-wrap">
                        <div className="tab-wrap">
                            <div className="tab-menu-wrap">
                                <ul className="tab-menu tab-menu-3">
                                    <li>
                                        <div onClick={this.chageStep(1)} className={"tab " + (this.state.step === 1 ? "selected" : "")}>
                                            <div className="txt-wrap">
                                                <span className="location">APP</span>
                                                <span className="txt">앱 이용안내</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div onClick={this.chageStep(2)} className={"tab " + (this.state.step === 2 ? "selected" : "")}>
                                            <div className="txt-wrap">
                                                <span className="location">B2</span>
                                                <span className="txt">센트럴·게이트</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div onClick={this.chageStep(3)} className={"tab " + (this.state.step === 3 ? "selected" : "")}>
                                            <div className="txt-wrap">
                                                <span className="location">G</span>
                                                <span className="txt">탑승장</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="tab-con-wrap">
                                <div className="inner">
                                    <div className="tab-con" style={{display: (this.state.step === 1) ? "" : "none"}}>
                                        <div className="notice">
                                            <div className="notice-con">
                                                <p className="ttl">레이스 이용 전 유의사항</p>
                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        <p>· 만 14세 이상 운전가능</p>
                                                        <p>· 신장 150cm - 190cm 이용 가능</p>
                                                        <p>· 뒷좌석에 동승자 탑승 시, <br/>
                                                            운전자는 만 19세 이상의 보호자</p>
                                                        <p>· 하이힐, 통굽 착용 시 이용 불가</p>
                                                        <p>· 앞 또는 뒤가 뚫린 신발 착용 시 이용 불가 <br/>
                                                            (Garage 981에서 덧신 구매 필요)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">9.81파크 앱 이용 안내</p>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-app.png" alt="981 PARK APP"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="notice-guide">
                                            <div className="notice-guide-con">
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. 앱 다운로드 후 티켓 등록 방법!</div>
                                                        <div className="txt">
                                                            티켓에 있는 QR코드를 인식하여 앱을
                                                            다운로드 받자! 앱에 티켓을 등록하면 탑승한 영상과 기록을 받아볼 수 있다.
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">2. 언제나 중요한 건 안!전!</div>
                                                        <div className="txt">
                                                            레이스 이용 전, 앱에서 안전영상을 시청하자!
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">3. 궁금증은 9.81파크 앱이 대답한다!</div>
                                                        <div className="txt">
                                                            <p>나의 티켓이 궁금하다면 어플에서 확인하자. <br/>
                                                                각 어트렉션별 위치와 횟수를 확인할 수 있고,
                                                                레이스 입장 시간과 코스를 확인하여
                                                                알맞게 탑승할 수 있다!</p>
                                                            <p>코스 3 > 1 > 2 는 3-1-2 순서대로, <br/>
                                                                코스 1 > 1 > 2 는 1-1-2 순서대로 탑승 가능!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-con" style={{display: (this.state.step === 2) ? "" : "none"}}>
                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">센트럴</p>
                                                    <div className="location">B2</div>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-central.png" alt="센트럴"/>
                                                </div>

                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        전광판에 나의 레이스 시간 입장 안내가 떴다면? <br/>
                                                        지금이다! 동료 레이서들과 차례차례 입장을
                                                        준비해보자
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="crew-guide">
                                            <div className="crew-guide-con">
                                                <div className="ttl-wrap">
                                                    <p className="ttl">크루의 안내는 아래와 같다.</p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. 소지품은 물품 보관소에!</div>
                                                        <div className="txt">
                                                            휴대폰을 제외한 모든 소지품은 가지고
                                                            탑승할 수 없으니, 물품보관소에 보관해주길!
                                                        </div>
                                                        <div className="ps-txt">
                                                            무료 물품보관소는 비상구에서 왼쪽으로
                                                            가면 찾을 수 있다.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">게이트</p>
                                                    <div className="location">B2</div>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-gate.png" alt="게이트"/>
                                                </div>

                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        입장 전 크루의 안내가 있을 예정! <br/>
                                                        안전영상 시청한 앱을 크루에게 보여주자!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="crew-guide">
                                            <div className="crew-guide-con">
                                                <div className="ttl-wrap">
                                                    <p className="ttl">크루의 안내는 아래와 같다.</p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. 티켓을 태그하고 입장하자!</div>
                                                        <div className="txt">
                                                            티켓의 ‘9.81’ 부분에 NFC칩이 내장되어
                                                            있다. 게이트 화살표에 ‘9.81’을 태그하자. <br/>
                                                            인식이 되었다면 흰색 불이 들어올 것, <br/>
                                                            그 때가 바로 입장할 타이밍!
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">2. 게이트 통과 시 주의!</div>
                                                        <div className="txt">
                                                            앞사람이 지나가고 나서 흰색불이 꺼져야만
                                                            내 차례가 된다. <br/>
                                                            흰색 불이 꺼질 때까지 기다렸다가
                                                            태그하자.
                                                        </div>
                                                    </div>
                                                    <div className="txt-con">
                                                        <div className="ttl">3. 레이스 하기 전에 알아야 할 두 가지!</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2"><b>1</b></div>
                                                                <div className="txt-area">
                                                                    <p className="txt"><b>안전 속도 안내</b></p>
                                                                    <p className="txt">GR (레이스981 차량)은 최고 속도에
                                                                        근접하면 삐- 삐- 삐- 소리로 알려주고,
                                                                        최고속도를 넘어가면 안전을 위해
                                                                        자동 브레이크가 작동한다. <br/>
                                                                        그러니 갑자기 느려지더라도
                                                                        놀라지 말고, 끝까지 안전운전 잊지 말자!</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2"><b>2</b></div>
                                                                <div className="txt-area">
                                                                    <p className="txt"><b>장애물이 나타나면?</b></p>
                                                                    <p className="txt">트랙 위 장애물이 있는 경우
                                                                        서행하길 바란다. <br/>
                                                                        장애물 때문에 제대로 레이싱을
                                                                        즐기지 못했다면, 레이스가 끝나고
                                                                        꼭 크루에게 알려주길.</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-con" style={{display: (this.state.step === 3) ? "" : "none"}}>
                                        <div className="list">
                                            <div className="list-con">
                                                <div className="ttl-wrap">
                                                    <p className="lg-ttl">탑승장</p>
                                                    <div className="location">G</div>
                                                </div>

                                                <div className="img">
                                                    <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/img-boarding.png" alt="탑승장"/>
                                                </div>

                                                <div className="txt-wrap">
                                                    <div className="txt">
                                                        GR 탑승 및 하차 시, 크루의 안내를 따라보자. <br/>
                                                        전문적인 크루가 그대의 레이스를
                                                        스무스하게 만들어 줄 테니
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="crew-guide">
                                            <div className="crew-guide-con">
                                                <div className="ttl-wrap">
                                                    <p className="ttl">크루의 안내는 아래와 같다.</p>
                                                </div>
                                                <div className="txt-wrap">
                                                    <div className="txt-con">
                                                        <div className="ttl">1. 안전하게 GR에 탑승해보자!</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">GR에 두발을 올려 선 다음 앉는다.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">GR 화면 오른쪽 부분에
                                                                        티켓의 ‘9.81’ 부분을 찍는다.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">핸드폰(주요 소지품)은 주머니 속에
                                                                        보관한다.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">1인승은 양쪽 어깨 위에 있는
                                                                        안전벨트를 교차해서 버클에 채워
                                                                        X자로 착용한다. <br/>
                                                                        2인승은 양 팔을 안전벨트에 끼운 후,
                                                                        버클을 채운다.</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="txt-con">
                                                        <div className="ttl">2. 잠깐! 3코스 GR-D를 탑승한다면
                                                            스크림 부스터를 얻을 기회!</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">9.81파크 앱의 [나의 활동] 탭을
                                                                        확인한다.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">상단 Scream Booster 배너를 탭한다.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">[게임 시작] 버튼을 누른 뒤 GR에
                                                                        티켓을 태그!</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">핸드폰은 좌석 앞에 있는 거치대에
                                                                        장착한다.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">5</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">핸드폰 화면에 [Ready] 문구가
                                                                        나오고 있다면 모든 준비 끝!</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">6</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">크루의 안내에 따라 출발선 앞 대기 후,
                                                                        브레이크에서 발을 떼면 핸드폰 화면이
                                                                        바뀌며 게임이 시작된다.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">7</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">제한시간 3초 안에 힘껏 소리를 질러
                                                                        GR-D만의 특별한 부스터를 획득해보자.</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="txt-con">
                                                        <div className="ttl">3. 레이싱 시작하기 전 준비 운동!</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">엑셀 없이 오직 오른발 앞에 있는
                                                                        브레이크로 속도를 조절한다.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">브레이크를 꾸-욱 밟은 채로
                                                                        기다리다가, 크루가 신호를 보내면
                                                                        앞에 있는 <b>노란색 선</b>까지 가서 멈춘다.</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/warm-up-1.svg" alt="준비 운동1"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">무거운 핸들을 좌우로 세게 움직이면서
                                                                        앞에 보이는 신호등 앞에서 멈춘다. <br/>
                                                                        (신호등까지 핸들 테스트하며 이동한다.)</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/warm-up-2.svg" alt="준비 운동2"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">신호등이 빨간색에서 초록색이 되면
                                                                        출발한다.</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/warm-up-3.svg" alt="준비 운동3"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="txt-con">
                                                        <div className="ttl">4. 레이스 중 꼭 지켜야 할 안내사항!</div>
                                                        <ul className="txt-list">
                                                            <li>
                                                                <div className="num typo2">1</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">주행 중 휴대폰 사용, 고의 정차, 충돌
                                                                        그리고 핸들에서 양손을 떼면
                                                                        실격	처리가 된다. (앱에서 기록과 영상
                                                                        또한 받아볼 수 없다.)</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">2</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">피니시 라인을 지나면 직접 브레이크를 	밟아 충분히 감속하며, 바닥에 보이는
                                                                        STOP 구간까지 가서 멈춘다.</p>
                                                                    <div className="img">
                                                                        <img src="https://asset.981park.com/www/common/mweb/img/hearing-impaired/race981/safety.gif" alt="레이스 중 주의사항"/>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">3</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">크루가 안내하는 위치까지 파란선을
                                                                        따라 서행운전하며 완전히 정차한다. <br/>
                                                                        크루의 안내에 따라 브레이크를 3초간
                                                                        강하게 눌렀다가 발을 뗀다. <br/>
                                                                        이때부터는 GR이 자동으로 올라간다. <br/>
                                                                        (자동주행중에는 핸드폰 사용이
                                                                        가능하니 지금부터 포토타임!)</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">4</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">자동주행 중 GR에 문제가 있거나
                                                                        앞 차량과 너무 가까워진다고 생각되면
                                                                        브레이크를 밟고 손을 들어도 된다.</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="num typo2">5</div>
                                                                <div className="txt-area">
                                                                    <p className="txt">하차 시에는 GR이 완전히 정차한 뒤에
                                                                        벨트를 풀고 크루의 안내에 따라
                                                                        하차한다.</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
		);
	}
}

export default withTranslation()(Race981GuideComp);