import React from "react";
import {withTranslation} from "react-i18next";

class MRankingRuleComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
		};
	}
	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/mweb/img/sub/race/slide-racing-rule1.png" alt="레이스 981을 게임처럼 즐겨보자!"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">RACING RULE</p>
									<p className="m-text">{t('GR_title4')}</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						
						<div className="list list-type5">
							<div className="list-con">
								<p className="lg-ttl">{t('Ranking_sub_title')}</p>
								<div className="txt-wrap">
									<div className="lg-txt">
										{t('Rule_sub_c1')}<br/>
										{t('Rule_sub_c1_1')}{t('Rule_sub_c1_2')}{t('Rule_sub_c1_3')}
									</div>
								</div>
							</div>	
						</div>
						
						<div className="list list-type6">
							<div className="list-con">
								<div className="list-txt list-txt-type1">	
									<p className="ttl">{t('Rule_sub_c2_title')}</p>
									<ul>
										<li>
											<p className="txt">
											{t('Rule_sub_c2')} <br/>
											{t('Rule_sub_c2_1')} <br/>
											{t('Rule_sub_c3')}
											</p>
										</li>
									</ul>
								</div>
							</div>
							<div className="list-con">
								<div className="list-txt list-txt-type1">	
									<p className="ttl">{t('Rule_sub_c4_title')}</p>
									<ul>
										<li>
											<p className="txt">
												{t('Rule_sub_c4')} <br/>
												{t('Rule_sub_c5')}
											</p>
										</li>
									</ul>
								</div>
							</div>
							<div className="list-con">
								<div className="list-txt list-txt-type1">
									<p className="ttl">{t('Rule_sub_c6_title')}</p>
									<ul>
										<li>
											<p className="txt">
											{t('Rule_sub_c6')} <br/> 
											{t('Rule_sub_c6_1')} <br/>
											{t('Rule_sub_c7')}
											</p>
										</li>
									</ul>
								</div>
							</div>	
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MRankingRuleComp);