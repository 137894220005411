import React, { Suspense } from 'react'; //eslint-disable-line no-unused-vars
import MainComponent from './components/MainComponent';
import MobileComponent from './components/MobileComponent';
import CNComponent from './components/CNComponent';
import MCNComponent from './components/MCNComponent';
import { isMobile } from "react-device-detect";
import PrivacyComp from "./components/common/PrivacyComp";
import MPrivacyComp from "./components/common/MPrivacyComp";

function App() {
  var content;
  if (window.location.href.includes('.com/cn') || window.location.href.includes('.net/cn') || window.location.href.includes('localhost:3333/cn')) {
    if (isMobile) {
      content = (<MCNComponent/>);
    } else {
      content = (<CNComponent/>);
    }
  } else if(window.location.href.includes('.com/privacy') || window.location.href.includes('.net/privacy') || window.location.href.includes('localhost:3333/privacy')) {
    if (isMobile) {
      content = (<MPrivacyComp/>);
    } else {
      content = (<PrivacyComp/>);
    }
  } else {
    if (isMobile) {
      content = (<MobileComponent/>);
    } else {
      content = (<MainComponent/>);
    }
  }
  return (
    <>{content}</>
  );
}

export default App;