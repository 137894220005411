import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LocalStorage from "./LocalStorage";
import translationEn from './en.json';
import translationKo from './ko.json';
var resource = {
  en: {
    translation: translationEn
  },
  ko: {
    translation: translationKo
  }
};
var lang = LocalStorage.getItem("i18Lang") || 'ko';
if (!i18n.isInitialized) {
  i18n.use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resource,
    lng: lang,
    fallbackLng: lang,
    // ns: ['translation'],
    // defaultNS: "translation",
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  i18n.on('languageChanged', function (lng) {
    LocalStorage.setItem("i18Lang", lng);
  });
}
export default i18n;