import React from "react";
import {withTranslation} from "react-i18next";
import RoutePath from '../../resource/RoutePath'
import { Link } from 'react-router-dom';

class PlayComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
		};
	}
	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-wrap">
					<div className="img">
						<img src="https://asset.981park.com/www/common/web/img/sub/play/play-bg1.jpg" alt="제주 자연과 함께 밖에서 놀자! 실외 액티비티 981"/>
					</div>
					<div className="inner">
						<div className="con">
							<div className="text-wrap color1">
								<p className="s-text typo1">PLAY INFORMATION</p>
								<p className="m-text">{t('PlayComp_title_1')} <br/> {t('PlayComp_title_2')}</p>
							</div>
						</div>	
					</div>
				</div>
				<div className="card-list-wrap">
					<div className="card-wrap">
						<div className="inner">
							<div className="con">
								<div className="card-list">
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/play/card-1-1.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/web/img/sub/play/race-981-logo.svg" alt="race-981"/></p>
											<div className="label-txt"><span>{t('PlayComp_btn_title')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.OUTRACE981} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>						
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/web/img/sub/play/card-1-2.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/web/img/sub/play/heal-swing-logo.svg" alt="heal-swing"/></p>
											<div className="label-txt"><span>{t('PlayComp_btn_title')}</span></div>
											<div className="button-wrap" style={{cursor: "pointer"}}>
												<Link to={`/`+RoutePath.OUTHEAL} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
								</div>
							</div>
						</div>					
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(PlayComp);