import React from "react";
import {withTranslation} from "react-i18next";
import Slider from "react-slick"; //eslint-disable-line no-unused-vars

class RulesComp  extends React.Component {
	constructor(props) {
		super(props);
		this.heal = React.createRef();
		this.proarena = React.createRef();
        this.arena = React.createRef();
		this.incube = React.createRef();
		this.ringgo = React.createRef();
		this.lab = React.createRef();
		this.vr = React.createRef();
	}
	
	componentDidMount() {
		if (window.location.hash !== "") {
			let hash = window.location.hash.replace("#", "")
			let hashResult = hash.split('?')[0]
			this.interval = setInterval(() => this.scrollToAnker(hashResult), 100);
		} else {
			window.scrollTo(0, 0);
		}
	}
	
	scrollToAnker(hash) {
		switch (hash) {
			case "heal":
				window.scrollTo(0, this.heal.current.offsetTop);
				break;
			case "arena":
				window.scrollTo(0, this.arena.current.offsetTop);
				break;
			case "proarena":
				window.scrollTo(0, this.proarena.current.offsetTop);
				break;
			case "incube":
				window.scrollTo(0, this.incube.current.offsetTop);
				break;
			case "ringgo":
				window.scrollTo(0, this.ringgo.current.offsetTop);
				break;
			case "lab":
				window.scrollTo(0, this.lab.current.offsetTop);
				break;
			case "vr":
				window.scrollTo(0, this.vr.current.offsetTop);
				break;
			default :
				window.scrollTo(0, 0);
		}
		clearTimeout(this.interval);
	}

	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list list-type4">
							<div className="list-ttl">
								<p className="s-ttl typo1">9.81 PARK</p>
								<p className="ttl">{t('Rule_title_1') + " " + t('Rule_title_2') }</p>
							</div>
							<div className="list-con-wrap">
								<p className="lg-ttl">{t('Rule_sub_title_1')}</p>
								<div className="list-con">
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_1_1')}</li>
												<li>{t('Rule_sub_title_1_2')}</li>
												<li>{t('Rule_sub_title_1_3')}</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							
							<div className="list-con-wrap">
								<p className="lg-ttl">{t('Rule_sub_title_2')}</p>
								<div className="list-con">							
									<p className="ttl">{t('Rule_sub_title_condition')}</p>
									<div className="txt-list-wrap txt-list-4">
										<div className="txt-list">
											<p className="ttl">GR-E</p>
											<div className="txt">
												<ul>
													<li>{t('Rule_sub_title_2_1')}</li>
													<li>{t('Rule_sub_title_2_2')}</li>
												</ul>
											</div>
										</div>
										<div className="txt-list">
											<p className="ttl">GR-D</p>
											<div className="txt">
												<p className="txt-ttl">{t('Rule_sub_title_2_3')}</p>
												<ul>
													<li>{t('Rule_sub_title_2_1')}</li>
													<li>{t('Rule_sub_title_2_5')} <br/> {t('Rule_sub_title_2_6')} <b>{t('Rule_sub_title_2_6_1')}</b></li>
													<li>{t('Rule_sub_title_2_7')}</li>
												</ul>
											</div>
											<div className="txt">
												<p className="txt-ttl">{t('Rule_sub_title_2_8')}</p>
												<ul>
													<li>{t('Rule_sub_title_2_9')}</li>
												</ul>
											</div>
										</div>
										<div className="txt-list">
											<p className="ttl">Junior GR-D</p>
											<div className="txt">
												<p className="txt-ttl">{t('Rule_sub_title_2_3')}</p>
												<ul>
													<li className="pl-58">{t('Rule_sub_title_2_10')}<br/>{t('Rule_sub_title_2_11')}</li>
													<li>{t('Rule_sub_title_2_12')}</li>
												</ul>
											</div>
											<div className="txt">
												<p className="txt-ttl">{t('Rule_sub_title_2_13')}</p>
												<ul>
													<li>{t('Rule_sub_title_2_14')}</li>
												</ul>
											</div>
										</div>
										<div className="txt-list">
											<p className="ttl">GR-X</p>
											<div className="txt">
												<ul>
													<li>{t('Rule_sub_title_2_14')}</li>
													<li>{t('Rule_sub_title_2_7')}</li>
													<li>{t('Rule_sub_title_2_15')}</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="txt-list-wrap txt-list-1">
										<div className="txt-list">
											<p className="ttl">{t('Rule_sub_title_sub')}</p>
											<div className="txt">
												<ul>
													<li>{t('Rule_sub_title_sub_1') + " "  + t('Rule_sub_title_sub_2')}</li>
													<li>{t('Rule_sub_title_sub_3')}</li>
													<li>{t('Rule_sub_title_sub_4')}</li>
													<li>{t('Rule_sub_title_sub_5')}</li>
													<li>{t('Rule_sub_title_sub_6')}</li>
													<li>{t('Rule_sub_title_sub_7')}</li>
													<li>{t('Rule_sub_title_sub_8')}</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							
								<div className="list-con">
									<p className="ttl"> {t('Rule_sub_title_rule')} </p>	
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_sub_9')}</li>
												<li>{t('Rule_sub_title_sub_10')}</li>
												<li>{t('Rule_sub_title_sub_11')}</li>
												<li>{t('Rule_sub_title_sub_12')}</li>
												<li>{t('Rule_sub_title_sub_13')}</li>
												<li>{t('Rule_sub_title_sub_14')}</li>
												<li>{t('Rule_sub_title_sub_15')}</li>
											</ul>
										</div>
									</div>	
								</div>
							</div>
							
							<div className="list-con-wrap" ref={this.vr}>
								<p className="lg-ttl">{t('Rule_sub_title_3')}</p>
								<div className="list-con">							
									<p className="ttl">{t('Rule_sub_title_condition')}</p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_3_1')}</li>
												<li>{t('Rule_sub_title_3_2')}</li>
												<li>{t('Rule_sub_title_3_3')}</li>
											</ul>
										</div>
									</div>
								</div>
								
								<div className="list-con">							
									<p className="ttl">{t('Rule_sub_title_rule')} </p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_3_4')}</li>
												<li>{t('Rule_sub_title_3_5')}</li>
												<li>{t('Rule_sub_title_3_6')}</li>
												<li>{t('Rule_sub_title_3_7')}</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							<div className="list-con-wrap" ref={this.proarena}>
								<p className="lg-ttl">{t('Rule_sub_title_4')}</p>
								<div className="list-con">
									<p className="ttl">{t('Rule_sub_title_condition')}</p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_2_1')}</li>
												<li>{t('Rule_sub_title_4_1')}</li>
												<li>{t('Rule_sub_title_4_2')}</li>
												<li>{t('Rule_sub_title_4_3')}</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="list-con">
									<p className="ttl">{t('Rule_sub_title_rule')} </p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_4_4')}</li>
												<li>{t('Rule_sub_title_4_5')}</li>
												<li>{t('Rule_sub_title_4_6')}</li>
												<li>{t('Rule_sub_title_4_7')}</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							<div className="list-con-wrap" ref={this.lab}>
								<p className="lg-ttl">{t('Rule_sub_title_5')}</p>
								<div className="list-con">							
									<p className="ttl">{t('Rule_sub_title_condition')} </p>
									<div className="txt-list-wrap txt-list-4">
										<div className="txt-list">
											<p className="ttl">{t('Rule_sub_title_5_1')}</p>
											<div className="txt">
												<ul>
													<li>{t('Rule_sub_title_5_8')}</li>
													<li>{t('Rule_sub_title_5_9')}</li>
												</ul>
											</div>
										</div>
										<div className="txt-list">
											<p className="ttl">{t('Rule_sub_title_5_2')}</p>
											<div className="txt">
												<ul>
													<li>{t('Rule_sub_title_5_10')}</li>
												</ul>
											</div>
										</div>
										<div className="txt-list">
											<p className="ttl">{t('Rule_sub_title_5_3')}</p>
											<div className="txt">
												<ul>
													<li>{t('Rule_sub_title_5_11')}</li>
												</ul>
											</div>
										</div>
										<div className="txt-list">
											<p className="ttl">{t('Rule_sub_title_5_4')}</p>
											<div className="txt">
												<ul>
													<li>{t('Rule_sub_title_5_12')}</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="txt-list-wrap txt-list-4">
										<div className="txt-list">
											<p className="ttl">{t('Rule_sub_title_5_5')}</p>
											<div className="txt">
												<ul>
													<li>{t('Rule_sub_title_5_13')}</li>
												</ul>
											</div>
										</div>
										<div className="txt-list">
											<p className="ttl">{t('Rule_sub_title_5_6')}</p>
											<div className="txt">
												<ul>
													<li>{t('Rule_sub_title_5_14')}</li>
												</ul>
											</div>
										</div>
										<div className="txt-list">
											<p className="ttl">{t('Rule_sub_title_5_7')}</p>
											<div className="txt">
												<ul>
													<li>{t('Rule_sub_title_5_15')}</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="list-con-wrap" ref={this.incube}>
								<p className="lg-ttl">{t('Rule_sub_title_9')}</p>
								<div className="list-con">
									<p className="ttl">{t('Rule_sub_title_condition')}</p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_9_1')}</li>
												<li>{t('Rule_sub_title_9_2')}</li>
												<li>{t('Rule_sub_title_9_3')}</li>
												<li>{t('Rule_sub_title_9_4')}</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="list-con">
									<p className="ttl">{t('Rule_sub_title_rule')} </p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_9_5')}</li>
												<li>{t('Rule_sub_title_9_6')}</li>
												<li>{t('Rule_sub_title_9_7')}</li>
												<li>{t('Rule_sub_title_9_8')}</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							
							<div className="list-con-wrap" ref={this.ringgo}>
								<p className="lg-ttl">{t('Rule_sub_title_6')}</p>
								<div className="list-con">							
									<p className="ttl">{t('Rule_sub_title_condition')}</p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_6_1')}</li>
												<li>{t('Rule_sub_title_6_2')}</li>
												<li>{t('Rule_sub_title_6_3')}</li>
												<li>{t('Rule_sub_title_6_4')}</li>
												<li>{t('Rule_sub_title_6_5')}</li>
												<li>{t('Rule_sub_title_6_6')}</li>
											</ul>
										</div>
									</div>
								</div>
								
								<div className="list-con">							
									<p className="ttl">{t('Rule_sub_title_rule')} </p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_6_7')}</li>
												<li>{t('Rule_sub_title_6_8')}</li>
												<li>{t('Rule_sub_title_6_9')}</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							
							<div className="list-con-wrap" ref={this.arena}>
								<p className="lg-ttl">{t('Rule_sub_title_7')}</p>
								<div className="list-con">
									<p className="ttl">{t('Rule_sub_title_condition')}</p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_7_1')}</li>
												<li>{t('Rule_sub_title_7_2')}</li>
												<li>{t('Rule_sub_title_7_3')}</li>
												<li>{t('Rule_sub_title_7_4')}</li>
											</ul>
										</div>
									</div>
								</div>
								
								<div className="list-con">							
									<p className="ttl">{t('Rule_sub_title_rule')} </p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_7_5')}</li>
												<li>{t('Rule_sub_title_7_6')}</li>
												<li>{t('Rule_sub_title_7_7')}</li>
												<li>{t('Rule_sub_title_7_8')}</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							
							<div className="list-con-wrap" ref={this.heal}>
								<p className="lg-ttl">{t('Rule_sub_title_8')}</p>
								<div className="list-con">							
									<p className="ttl">{t('Rule_sub_title_condition')}</p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_8_1')}</li>
												<li>{t('Rule_sub_title_8_2')}</li>
												<li>{t('Rule_sub_title_8_3')}</li>
												<li>{t('Rule_sub_title_8_4')}</li>
												<li>{t('Rule_sub_title_8_5')}</li>
												<li>{t('Rule_sub_title_8_6')}</li>
												<li>{t('Rule_sub_title_8_7')}</li>
												<li>{t('Rule_sub_title_8_8')}</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="list-con">							
									<p className="ttl">{t('Rule_sub_title_rule')} </p>
									<div className="txt-wrap">
										<div className="txt">
											<ul>
												<li>{t('Rule_sub_title_8_9')}</li>
												<li>{t('Rule_sub_title_8_10')}</li>
												<li>{t('Rule_sub_title_8_11')}</li>
												<li>{t('Rule_sub_title_8_12')}</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(RulesComp);