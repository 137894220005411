import React from "react";
import {withTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import RoutePath from '../../resource/RoutePath'
import { findGoingBisTimeString, findGoingPreTimeString } from "../../common/Util";

class FLBroComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
		};
	}
	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/web/img/sub/play/slide-broccollege1.png" alt="BROCCOLLEGE"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">BROCCOLLEGE</p>
									<p className="m-text"> {t('InBroComp_title_1')} <br/> {t('InBroComp_title_2')} </p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list">
							<div className="list-con">
								<p className="lg-ttl">{t('InBroComp_title_tag')}</p>
								<div className="img">
									<img src="https://asset.981park.com/www/common/web/img/sub/play/img-broccollege1.png" alt="broccollege"/>
								</div>
								<div className="txt-wrap">
									<div className="txt">
										{t('InBroComp_title_contents_1')} <br/><br/>
										{t('InBroComp_title_contents_2')} <br/>
										{t('InBroComp_title_contents_3')} <br/>
										{t('InBroComp_title_contents_4')}
									</div>
								</div>
								<div className="button-wrap">
									<Link to={`/`+RoutePath.PARKFLEX} style={{cursor: "pointer"}} className="link-detail-more">{t('FLBroComp_c_sub_1_5')}</Link>
								</div>
							</div>	
							
							<div className="list-con">
								<p className="lg-ttl">{t('InBroComp_title_time')}</p>
								<div className="button-wrap">
									<Link to={`/`+RoutePath.OPERATION} style={{cursor: "pointer"}} className="link-detail-more">{t('OperationComp_c_title_4_2')}</Link>
								</div>
							</div>				
						</div>
					</div>
				</div>
				
			</div>
		);
	}
}
export default withTranslation()(FLBroComp);