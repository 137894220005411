import React from "react";
import {withTranslation} from "react-i18next";
import RoutePath from "../../resource/RoutePath";
import { Link } from 'react-router-dom';

class MPlayFlexComp  extends React.Component {
	constructor(props) {
		super(props);
		this.play = React.createRef();
		this.state = {
		};
	}

	scrollContent = () => () => {
        this.play.current.scrollIntoView({ behavior: 'smooth'});
    };

	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-wrap">
					<div className="img">
						<img src="https://asset.981park.com/www/common/mweb/img/sub/play/play-bg3.jpg" alt="트렌디한 공간을 누비며 안에서 놀자! 실내 액티비티 981"/>
					</div>
					<div className="inner">
						<div className="con">
							<div className="text-wrap color1">
								<p className="s-text typo1">PLAY INFORMATION</p>
								<p className="m-text">{t('PlayFlexComp_title_1')}<br/>{t('PlayFlexComp_title_2')}<br/>{t('PlayFlexComp_title_3')}</p>
							</div>
						</div>	
					</div>
				</div>
				<div className="card-list-wrap">
					<div className="card-wrap">
						<div className="inner">
							<div className="con">
								<div className="card-list">
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-3-1.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/garage-logo.svg" alt="garage 981" className="garage-logo"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.FLGARAGE} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>						
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-3-6.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/img-logo-photodrink.png" alt="photodrink" className="photodrink-logo"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.FLPHOTO} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>						
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-3-5.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/broccollege-logo.svg" alt="broccollege" className="broccollege-logo"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.FLBRO} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-3-7.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/booster-station-logo.svg" alt="booster-station" className="booster-station-logo"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.FLBOOSTER} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-3-8.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/onatz-logo.svg" alt="onatz" className="onatz-logo"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.FLONATZ} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-3-4.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/loungex-logo.svg" alt="loungex" className="loungex-logo"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.FLLOUNGE} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
									<div className="card">
										<div className="card-inner">
											<img src="https://asset.981park.com/www/common/mweb/img/sub/play/card-3-2.png" alt="9.81 파크" className="img img-1"/>
											<p className="text"><img src="https://asset.981park.com/www/common/mweb/img/sub/play/zero-logo.svg" alt="zero" className="zero-logo"/></p>
											<div className="label-txt"><span>{t('PlayFlexComp_btn_title2')}</span></div>
											<div className="button-wrap">
												<Link to={`/`+RoutePath.FLSPACE} className="link-more"><span className="blind">more</span></Link>
											</div>
										</div>					
									</div>
								</div>
							</div>
						</div>					
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MPlayFlexComp);