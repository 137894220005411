import React from "react";
import i18next from "i18next";
import {withTranslation} from "react-i18next";

class ConvenientComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
		};
	}
	render() {
		const {t} = this.props;
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/web/img/sub/park/slide-convenient-facilities1.jpg" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
							</div>
						</div>			
					</div>
					
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">CONVENIENT FACILITIES</p>
									<p className="m-text">{t('ConvenientComp_title_1')} <br/> {t('ConvenientComp_title_2')}</p>
								</div>
							</div>	
						</div>	
					</div>
					
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list list-type1">
							<div className="img">
								<img src={"https://asset.981park.com/www/common/web/img/sub/park/img-map3" + (i18next.language === "en" ? "_en.png" : ".svg")} alt="CONVENIENT FACILITIES"/>
							</div>
							<ul>
								<li>
									<p className="ttl">{t('ConvenientComp_c_1')}</p>
									<p className="txt">
										{t('ConvenientComp_c_1_1')}
									</p>
								</li>
								<li>
									<p className="ttl">{t('ConvenientComp_c_2')}</p>
									<p className="txt">
										{t('ConvenientComp_c_2_1')}
									</p>
								</li>
								<li>
									<p className="ttl">{t('ConvenientComp_c_3')}</p>
									<p className="txt">
										{t('ConvenientComp_c_3_1')}
									</p>
								</li>
								<li>
									<p className="ttl">{t('ConvenientComp_c_4')}</p>
									<p className="txt">
										{t('ConvenientComp_c_4_1')}
									</p>
								</li>
								<li>
									<p className="ttl">{t('ConvenientComp_c_5')}</p>
									<p className="txt">
									{t('ConvenientComp_c_5_1')}
									</p>
								</li>
								<li>
									<p className="ttl">{t('ConvenientComp_c_6')}</p>
									<p className="txt">
										{t('ConvenientComp_c_6_1')}
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(ConvenientComp);