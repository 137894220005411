import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.location.pathname !== "/TICKET" && this.props.location.pathname !== "/RULES" && this.props.location.pathname !== "/MTICKET" && this.props.location.pathname !== "/MRULES") {
        window.scrollTo(0, 0)
      }
    }
  }

  render() {
    return <div>{this.props.children}</div>
  }
}

export default withRouter(ScrollToTop)