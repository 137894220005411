import React from "react";
import {withTranslation} from "react-i18next";
import {Route} from "react-router-dom";

class MPrivacyComp  extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {t} = this.props;

		return (
			<Route path='/privacy' component={() => {
				window.location.href = 'https://app.catchsecu.com/document/P/24f7518177892ee';
				return null;
			}}/>
		);
	}
}
export default withTranslation()(MPrivacyComp);