var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import RoutePath from '../constant/route';
import i18next from "../i18n";
import './index.scss';
var DesktopFooter = function DesktopFooter() {
  var _a = useState(false),
    showTopButton = _a[0],
    setShowTopButton = _a[1];
  var t = useTranslation().t;
  var changeLang = function changeLang(e) {
    i18next.changeLanguage(e.target.value);
    location.reload();
  };
  var handleScroll = function handleScroll() {
    var scrollTop = window.scrollY;
    if (scrollTop > 250) {
      setShowTopButton(true);
    } else {
      setShowTopButton(false);
    }
  };
  useEffect(function () {
    window.addEventListener('scroll', handleScroll);
    return function () {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  var scrollToTop = function scrollToTop() {
    return window.scrollTo(0, 0);
  };
  var moveToBoard = function moveToBoard() {
    if (location.hostname.includes('localhost')) {
      window.location.href = 'http://localhost:3000/board';
      return;
    }
    window.location.href = "/".concat(RoutePath.BOARD);
  };
  var openWindow = function openWindow() {
    window.open('https://race981.page.link/web_hero?utm_source=direct&utm_medium=button&utm_campaign=hero');
  };
  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      onScroll: handleScroll
    }), _jsx("aside", {
      children: _jsx("div", __assign({
        className: "site-map-wrap"
      }, {
        children: _jsx("div", __assign({
          className: "inner"
        }, {
          children: _jsxs("ul", __assign({
            className: "site-map"
          }, {
            children: [_jsxs("li", {
              children: [_jsx("a", __assign({
                href: "/" + RoutePath.PARK
              }, {
                children: "PARK"
              })), _jsxs("ul", __assign({
                className: "depth-3-wrap"
              }, {
                children: [_jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.BOARD,
                    onClick: moveToBoard
                  }, {
                    children: t('Park_sub0_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.ACTIVITY
                  }, {
                    children: t('Park_sub1_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.PARKFLEX
                  }, {
                    children: t('Park_sub2_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.CONVENIENT
                  }, {
                    children: t('Park_sub3_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.SHUTTLE
                  }, {
                    children: t('Park_sub4_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.OPERATION
                  }, {
                    children: t('Park_sub5_title')
                  }))
                })]
              }))]
            }), _jsxs("li", __assign({
              style: {
                paddingLeft: i18next.language === "ko" ? "65px" : "50px"
              }
            }, {
              children: [_jsx("a", __assign({
                href: "/" + RoutePath.PLAY
              }, {
                children: "PLAY"
              })), _jsxs("ul", __assign({
                className: "depth-2-wrap"
              }, {
                children: [_jsxs("li", {
                  children: [_jsx("a", __assign({
                    href: "/" + RoutePath.PLAY
                  }, {
                    children: "OUTDOOR ACTIVITY"
                  })), _jsxs("ul", __assign({
                    className: "depth-3-wrap"
                  }, {
                    children: [_jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.OUTRACE981
                      }, {
                        children: t('Play_sub1_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.OUTHEAL
                      }, {
                        children: t('Play_sub2_title')
                      }))
                    })]
                  }))]
                }), _jsxs("li", {
                  children: [_jsx("a", __assign({
                    href: "/" + RoutePath.INDOOR
                  }, {
                    children: "INDOOR ACTIVITY"
                  })), _jsxs("ul", __assign({
                    className: "depth-3-wrap"
                  }, {
                    children: [_jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INMETA
                      }, {
                        children: t('Play_sub4_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INPROARENA
                      }, {
                        children: t('Play_sub32_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INLAB
                      }, {
                        children: t('Play_sub6_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INCUBE
                      }, {
                        children: t('Play_sub14_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INRINGGO
                      }, {
                        children: t('Play_sub5_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.INARENA
                      }, {
                        children: t('Play_sub3_title')
                      }))
                    })]
                  }))]
                }), _jsxs("li", {
                  children: [_jsx("a", __assign({
                    href: "/" + RoutePath.PLAYFLEX
                  }, {
                    children: "FLEX & RELAX"
                  })), _jsxs("ul", __assign({
                    className: "depth-3-wrap"
                  }, {
                    children: [_jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLGARAGE
                      }, {
                        children: t('Play_sub7_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLPHOTO
                      }, {
                        children: t('Play_sub11_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLBRO
                      }, {
                        children: t('Play_sub9_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLBOOSTER
                      }, {
                        children: t('Play_sub12_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLONATZ
                      }, {
                        children: t('Play_sub13_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLLOUNGE
                      }, {
                        children: t('Play_sub10_title')
                      }))
                    }), _jsx("li", {
                      children: _jsx("a", __assign({
                        href: "/" + RoutePath.FLSPACE
                      }, {
                        children: t('Play_sub8_title')
                      }))
                    })]
                  }))]
                })]
              }))]
            })), _jsxs("li", __assign({
              style: {
                paddingLeft: i18next.language === "ko" ? "65px" : "50px"
              }
            }, {
              children: [_jsx("a", __assign({
                href: "/" + RoutePath.RACE981
              }, {
                children: "RACE 981"
              })), _jsxs("ul", __assign({
                className: "depth-3-wrap"
              }, {
                children: [_jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.GRAVITYRACER
                  }, {
                    children: t('Race_sub1_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.LICENSE
                  }, {
                    children: t('Race_sub2_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.BATTLE
                  }, {
                    children: t('Race_sub3_title')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.RANKINGRULE
                  }, {
                    children: t('Race_sub5_title')
                  }))
                })]
              }))]
            })), _jsxs("li", __assign({
              className: "groc",
              style: {
                paddingLeft: i18next.language === "ko" ? "65px" : "50px"
              }
            }, {
              children: [_jsx("a", __assign({
                href: "/" + RoutePath.GROC
              }, {
                children: "GROC"
              })), _jsxs("ul", __assign({
                className: "depth-3-wrap"
              }, {
                children: [_jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.ABOUTGROC
                  }, {
                    children: t('Groc_about')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.HALLOFFAME
                  }, {
                    children: t('Groc_hall')
                  }))
                })]
              }))]
            })), _jsxs("li", __assign({
              style: {
                paddingLeft: i18next.language === "ko" ? "65px" : "50px"
              }
            }, {
              children: [_jsx("a", __assign({
                href: "/" + RoutePath.TICKET
              }, {
                children: "TICKET"
              })), _jsxs("ul", __assign({
                className: "depth-3-wrap"
              }, {
                children: [_jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.GROUP
                  }, {
                    children: t('Ticket_sub1_title_2')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.TEAMROOM
                  }, {
                    children: t('Ticket_sub2_title_2')
                  }))
                })]
              }))]
            }))]
          }))
        }))
      }))
    }), _jsx("a", __assign({
      className: "desktop-link-top" + (showTopButton ? " show" : ""),
      onClick: scrollToTop,
      style: {
        cursor: "pointer"
      }
    }, {
      children: _jsx("span", __assign({
        className: "blind"
      }, {
        children: "top"
      }))
    })), _jsx("footer", __assign({
      className: "desktop-footer"
    }, {
      children: _jsx("div", __assign({
        className: "footer-wrap"
      }, {
        children: _jsx("div", __assign({
          className: "inner"
        }, {
          children: _jsxs("div", __assign({
            className: "info-wrap"
          }, {
            children: [_jsxs("div", __assign({
              className: "info_policy"
            }, {
              children: [_jsxs("ul", __assign({
                className: "policy-list"
              }, {
                children: [_jsx("li", {
                  children: _jsx("a", __assign({
                    href: 'https://app.catchsecu.com/document/P/24f7518177892ee',
                    target: "_blank"
                  }, {
                    children: t('FooterComp_pri')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.TERMS,
                    style: {
                      cursor: "pointer"
                    }
                  }, {
                    children: t('FooterComp_terms')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.RULES,
                    style: {
                      cursor: "pointer"
                    }
                  }, {
                    children: t('FooterComp_rules')
                  }))
                })]
              })), _jsx("div", __assign({
                className: "btn-list"
              }, {
                children: _jsx("div", __assign({
                  className: "select-box-wrap"
                }, {
                  children: _jsxs("select", __assign({
                    value: i18next.language,
                    name: "",
                    id: "",
                    onChange: changeLang
                  }, {
                    children: [_jsx("option", __assign({
                      value: "ko"
                    }, {
                      children: t('FooterComp_lang_ko')
                    })), _jsx("option", __assign({
                      value: "en"
                    }, {
                      children: t('FooterComp_lang_en')
                    }))]
                  }))
                }))
              })), _jsxs("div", __assign({
                className: "icon-list"
              }, {
                children: [_jsxs("div", __assign({
                  className: "sns-list"
                }, {
                  children: [_jsx("a", __assign({
                    href: 'https://www.facebook.com/981park',
                    target: "_blank",
                    className: "sns"
                  }, {
                    children: _jsx("img", {
                      src: "https://asset.981park.com/www/common/web/img/common/sns/00-common-footer-btn-01-facebook.svg",
                      alt: "facebook"
                    })
                  })), _jsx("a", __assign({
                    href: 'https://www.instagram.com/9.81park/',
                    target: "_blank",
                    className: "sns"
                  }, {
                    children: _jsx("img", {
                      src: "https://asset.981park.com/www/common/web/img/common/sns/00-common-footer-btn-02-instar.svg",
                      alt: "instar"
                    })
                  })), _jsx("a", __assign({
                    href: 'https://www.youtube.com/channel/UCdPUWUu5ZOWegvaqqwj7hfg',
                    target: "_blank",
                    className: "sns"
                  }, {
                    children: _jsx("img", {
                      src: "https://asset.981park.com/www/common/web/img/common/sns/00-common-footer-btn-03-youtube.svg",
                      alt: "youtube"
                    })
                  })), _jsx("a", __assign({
                    href: 'https://blog.naver.com/981jeju',
                    target: "_blank",
                    className: "sns"
                  }, {
                    children: _jsx("img", {
                      src: "https://asset.981park.com/www/common/web/img/common/sns/00-common-footer-btn-04-blog.svg",
                      alt: "blog"
                    })
                  }))]
                })), _jsxs("div", __assign({
                  className: "download-list"
                }, {
                  children: [_jsxs("a", __assign({
                    onClick: openWindow,
                    className: "download",
                    style: {
                      cursor: "pointer"
                    }
                  }, {
                    children: [_jsx("img", {
                      src: "https://asset.981park.com/www/common/web/img/common/sns/00-common-footer-btn-05-googleplay.svg",
                      alt: "Google play"
                    }), _jsx("span", __assign({
                      className: "txt"
                    }, {
                      children: "Google play"
                    }))]
                  })), _jsxs("a", __assign({
                    onClick: openWindow,
                    className: "download",
                    style: {
                      cursor: "pointer"
                    }
                  }, {
                    children: [_jsx("img", {
                      src: "https://asset.981park.com/www/common/web/img/common/sns/00-common-footer-btn-06-apple.svg",
                      alt: "App Store"
                    }), _jsx("span", __assign({
                      className: "txt"
                    }, {
                      children: "App Store"
                    }))]
                  })), _jsxs("a", __assign({
                    href: 'https://asset.981park.com/apk/app-apk-release.apk',
                    target: "_blank",
                    className: "download"
                  }, {
                    children: [_jsx("img", {
                      src: "https://asset.981park.com/www/common/web/img/common/sns/00-common-footer-btn-07-download.svg",
                      alt: "Download"
                    }), _jsx("span", __assign({
                      className: "txt"
                    }, {
                      children: "Download"
                    }))]
                  }))]
                }))]
              })), _jsx("div", __assign({
                className: "copyright"
              }, {
                children: _jsx("p", __assign({
                  className: "text"
                }, {
                  children: "Copyright \u24D2 MONOLITH & MONOLITH JEJU PARK INC.All rights reserved."
                }))
              }))]
            })), _jsxs("div", __assign({
              className: "info-company",
              style: {
                marginLeft: i18next.language === "ko" ? "180px" : "120px"
              }
            }, {
              children: [_jsxs("p", __assign({
                className: "text"
              }, {
                children: [_jsx("b", {
                  children: t('FooterComp_seoul_company')
                }), " ", _jsx("br", {}), i18next.language === "ko" ? _jsxs(_Fragment, {
                  children: [t('FooterComp_seoul_company_1'), _jsx("br", {}), t('FooterComp_seoul_company_2'), _jsx("br", {}), t('FooterComp_seoul_company_3')]
                }) : _jsxs(_Fragment, {
                  children: [t('FooterComp_seoul_company_1'), _jsx("br", {}), t('FooterComp_seoul_company_2'), _jsx("br", {}), t('FooterComp_seoul_company_3_1'), _jsx("br", {}), t('FooterComp_seoul_company_3_2')]
                })]
              })), _jsxs("p", __assign({
                className: "text"
              }, {
                children: [_jsx("b", {
                  children: t('FooterComp_jeju_company')
                }), " ", _jsx("br", {}), i18next.language === "ko" ? _jsxs(_Fragment, {
                  children: [t('FooterComp_jeju_company_1'), _jsx("br", {}), t('FooterComp_jeju_company_2'), _jsx("br", {}), t('FooterComp_jeju_company_3'), _jsx("br", {}), t('FooterComp_jeju_company_4')]
                }) : _jsxs(_Fragment, {
                  children: [t('FooterComp_jeju_company_1'), _jsx("br", {}), t('FooterComp_jeju_company_2'), _jsx("br", {}), t('FooterComp_jeju_company_3_3'), _jsx("br", {}), t('FooterComp_jeju_company_3_4'), _jsx("br", {}), t('FooterComp_jeju_company_3_2'), _jsx("br", {}), t('FooterComp_jeju_company_4')]
                })]
              }))]
            }))]
          }))
        }))
      }))
    }))]
  });
};
export default withTranslation()(DesktopFooter);