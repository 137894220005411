import i18next from "../resource/i18n";

export const findStartTimeString = (opTimes,name) => {
    for (var i=0; i<opTimes.length; i++) {
        if (opTimes[i].place_name === name) {
            if (opTimes[i].use_yn) {
                return opTimes[i].start_time + ` ~ ` + opTimes[i].end_time
            } else {
                return i18next.language === "ko" ? "*임시운휴": "A temporary holiday";
            }
        }
    }
    return ""
}
export const findLastTimeString = (opTimes, name) => {
    for (var i=0; i<opTimes.length; i++) {
        if (opTimes[i].place_name === name) {
            if (opTimes[i].use_yn) {
                return opTimes[i].last_entry_time
            } else {
                return i18next.language === "ko" ? "*임시운휴": "A temporary holiday";
            }
        }
    }
    return ""
}

export const findTimeString = (hours, name, diffName) => {
    var timeStr = "00:00 ~ 00:00 (" + diffName  + " 00:00)";
    if (hours !== null) {
        var startStr = findStartTimeString(hours.placeOpTimes,name);
        var lastStr = findLastTimeString(hours.placeOpTimes,name)
        if (startStr === "*임시운휴") {
            timeStr = (i18next.language === "ko" ? "*임시운휴": "A temporary holiday");
        } else {
            timeStr = startStr + ` (`+ diffName + " " + lastStr + `)`;
        }
    }
    return timeStr;
}

export const findTimeSimpleString = (hours, name) => {
    var timeStr = "00:00 ~ 00:00";
    if (hours !== null) {
        timeStr = findStartTimeString(hours.placeOpTimes,name);
    }
    return timeStr;
}

export const findGoingBisTimeString = (hours, name, diffName) => {
    var bisword = (i18next.language === "ko" ? "비스트로메리: ": "BISTRO MARY: ") 
    var timeStr = bisword +"00:00 ~ 00:00 (" + diffName  + " 00:00)";
    if (hours !== null) {
        var startStr = findStartTimeString(hours.placeOpTimes,name);
        var lastStr = findLastTimeString(hours.placeOpTimes,name)
        if (startStr === "*임시운휴") {
            timeStr = bisword + (i18next.language === "ko" ? "*임시운휴": "A temporary holiday");
        } else {
            timeStr = bisword + startStr + ` (`+ diffName + " " + lastStr + `)`;
        }
    }
    return timeStr;
}

export const findGoingPreTimeString = (hours, name, diffName) => {
    var freword = (i18next.language === "ko" ? "프레스코메리: ": "FRESCO MARY: ") 
    var timeStr = freword + "00:00 ~ 00:00 (" + diffName  + " 00:00)";
    if (hours !== null) {
        var startStr = findStartTimeString(hours.placeOpTimes,name);
        timeStr = freword + startStr
    }
    return timeStr;
}