import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import {DesktopHeader, DesktopFooter, MobileHeader, MobileFooter} from '@monolith/common/dist';
import TermsComp from './common/TermsComp';
import RulesComp from './common/RulesComp';
import {withTranslation} from "react-i18next";
import RoutePath from "../resource/RoutePath";
import HomeComp from './home/HomeComp';
import MMSComp from './MMSComponents';
import PromoScootComp from './PromoScootComp';
import PromoHotelComp from './PromoHotelComp';
import {ParkComp, ActivityComp, ParkFlexComp, ConvenientComp, ShuttleComp, OperationComp} from './park';
import {
    PlayComp,
    OutRaceComp,
    OutHealComp,
    IndoorComp,
    InArenaComp,
    InProArenaComp,
    InMetaComp,
    InRinggoComp,
    InLabComp,
    InCubeVerseComp,
    PlayFlexComp,
    FLGarageComp,
    FLPhotoComp,
    FLSpaceComp,
    FLBroComp,
    FLBoosterComp,
    FLLoungeComp,
    FLOnatzComp
} from './play';
import {RaceComp, GravityRacerComp, LicenseComp, BattleComp, RankingComp, RankingRuleComp} from './race';
import {NewGrocComp, AboutGROC, HallOffFame} from './groc';
import {TicketComp, TeamRoomComp, GroupComp} from './ticket';
import 'url-search-params-polyfill';
import i18next from "../resource/i18n";
import ScrollToTop from './common/ScorllToTop';
import axios from 'axios';

import { bake_cookie, read_cookie } from 'sfcookies'

export const DevServer = "https://dev-apis.981park.net"
export const RealServer = "https://apis-jeju.981park.com"

const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'X-Authorization-Client-Secret': '586f474c-50b1-11eb-b88b-02d92b41ce80',
    'X-Authorization-Client-Id': '981park-customer-home'
}

class MainComponent  extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            selectMenu: RoutePath.HOME,
            hoursData: null,
            noSee: true
        };
        var params = new URLSearchParams(window.location.search);

        this.menuChangeHandler = this.menuChangeHandler.bind(this);
        if (params.get("lang") == "en") {
            i18next.changeLanguage('en');
        }
        // if (params.get("url") !== null) {
        //     var urlString = params.get("url")
        //     axios({
        //         url: urlString,
        //         method: 'get',
        //         headers: headers
        //       }).then(response => {
        //       })
        // }
    }

    componentDidUpdate(){
        window.onpopstate  = (e) => {
            let path = window.location.pathname.replace("/", "")
            switch (path) {
                case RoutePath.HOME:
                    this.setState({selectMenu:RoutePath.HOME});
                    break;
                case RoutePath.PARK:
                case RoutePath.ACTIVITY:
                case RoutePath.PARKFLEX:
                case RoutePath.CONVENIENT:
                case RoutePath.SHUTTLE:
                case RoutePath.OPERATION:
                    this.setState({selectMenu:RoutePath.PARK});
                    break;
                case RoutePath.PLAY:
                case RoutePath.OUTRACE981:
                case RoutePath.OUTHEAL:
                case RoutePath.INDOOR:
                case RoutePath.INARENA:
                case RoutePath.INPROARENA:
                case RoutePath.INMETA:
                case RoutePath.INRINGGO:
                case RoutePath.INLAB:
                case RoutePath.INCUBE:
                case RoutePath.PLAYFLEX:
                case RoutePath.FLGARAGE:
                case RoutePath.FLPHOTO:
                case RoutePath.FLSPACE:
                case RoutePath.FLBRO:
                case RoutePath.FLLOUNGE:
                    this.setState({selectMenu:RoutePath.PLAY});
                    break;
                case RoutePath.RACE981:
                case RoutePath.GRAVITYRACER:
                case RoutePath.LICENSE:
                case RoutePath.BATTLE:
                case RoutePath.RANKING:
                case RoutePath.RANKINGRULE:
                    this.setState({selectMenu:RoutePath.RACE981});
                    break;
                case RoutePath.GROC:
                case RoutePath.ABOUTGROC:
                case RoutePath.HALLOFFAME:
                case RoutePath.GROC:
                    this.setState({selectMenu:RoutePath.GROC});
                    break;
                case RoutePath.TEAMROOM:
                case RoutePath.GROUP:
                case RoutePath.TICKET:
                    this.setState({selectMenu:RoutePath.TICKET});
                    break;
                default :
                    this.setState({selectMenu:RoutePath.HOME});
            }
        }
    }

    componentDidMount() {
        window.onpopstate  = (e) => {
        }
        require('../common/web/css/index.css');
    }

    menuChangeHandler(menu) {
        this.setState({selectMenu:menu});
    }

    closeNosee = () => () => {
        this.setState({noSee: false})
	}

	render() {
        const {t} = this.props;
        var ePopup = <div className="layer-popup race981-popup">
                <div className="layer-popup-inner">
                    <div className="content">
                        <button type="button" className="btn close" onClick={this.closeNosee()}><span className="blind">닫기</span></button>
                        <div className="con">
                            <div className="txt-wrap">
                                <div className="ttl">
                                    <span>{t('Notice_title')}</span>
                                </div>
                                <div className="txt">
                                    <span>{t('Notice_content1')}</span>
                                    <span>{t('Notice_content2')}</span>
                                    <span>{t('Notice_content3')}</span><br/>
                                    <span>{t('Notice_content6')}</span><br/>
                                    <span>{t('Notice_content4')}</span>
							        <span>{t('Notice_content5')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        if (!this.state.noSee) {
            ePopup = null
        }
        ePopup = null
        return (
            <Router>
                <ScrollToTop>
                    <Switch>
                        <Route exact path={`/` + RoutePath.MMS}>
                            <MMSComp/>
                        </Route>
                        <Route exact path={`/` + RoutePath.PROMOSCOOT}>
                            <PromoScootComp/>
                        </Route>
                        <Route exact path={`/` + RoutePath.PROMOHOTEL}>
                            <PromoHotelComp/>
                        </Route>
                        <Route exact path={`/` + RoutePath.HOME}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <HomeComp menuHandler={this.menuChangeHandler}/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.PARK}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <ParkComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.ParkAbout}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <ParkComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.ACTIVITY}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <ActivityComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.PARKFLEX}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <ParkFlexComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.CONVENIENT}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <ConvenientComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.SHUTTLE}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <ShuttleComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.OPERATION}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <OperationComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.PLAY}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <PlayComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.OUTRACE981}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <OutRaceComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.OUTHEAL}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <OutHealComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INDOOR}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <IndoorComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.Lab981}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <OutHealComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                            <IndoorComp />
                            <DesktopFooter />
                        </Route>
                        <Route path={`/` + RoutePath.INARENA}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <InArenaComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INPROARENA}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <InProArenaComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INMETA}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <InMetaComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INCUBE}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <InCubeVerseComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INRINGGO}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <InRinggoComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.INLAB}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <InLabComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.PLAYFLEX}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <PlayFlexComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLGARAGE}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <FLGarageComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLPHOTO}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <FLPhotoComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLSPACE}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <FLSpaceComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLBRO}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <FLBroComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLBOOSTER}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <FLBoosterComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLONATZ}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <FLOnatzComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.FLLOUNGE}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <FLLoungeComp/>
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.RACE981}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <RaceComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.GRAVITYRACER}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <GravityRacerComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.LICENSE}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <LicenseComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.BATTLE}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <BattleComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.RANKING}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <RankingComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.RANKINGRULE}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <RankingRuleComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.GROC}>
                            <div className="park-wrap groc-fullscreen">
                                <DesktopHeader />
                                <NewGrocComp />
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.ABOUTGROC}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <AboutGROC />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.HALLOFFAME}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <HallOffFame />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.TICKET}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <TicketComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.GROUP}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <GroupComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.TEAMROOM}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <TeamRoomComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.TERMS}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <TermsComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.Notice}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <TermsComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                        <Route path={`/` + RoutePath.RULES}>
                            <div className="park-wrap add-text-banner">
                                <DesktopHeader />
                                <RulesComp />
                                <DesktopFooter />
                                {ePopup}
                            </div>
                        </Route>
                    </Switch>
                </ScrollToTop>
            </Router>
		);
	}
}
export default withTranslation()(MainComponent);