import React from "react";
import i18next from "i18next";
import {withTranslation} from "react-i18next";
import Slider from "react-slick";

class MActivityComp  extends React.Component {
	constructor(props) {
        super(props);
		this.state = {
			slideIndex: 0
		};
	}

	changeSlideTo = idx => () => {
		this.setState({slideIndex: idx}, this.slider.slickGoTo(idx));
	};
	
	render() {
		const {t} = this.props;
		const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			afterChange: current => this.setState({slideIndex: current})
		};
		
		return (
			<div className="content-wrap sub-content">
				<div className="visual-slide-wrap">
					<div className="visual-slide-list">
						<div className="slide">
							<div className="img">
								<img src="https://asset.981park.com/www/common/mweb/img/sub/park/slide-activity-space1.jpg" alt="9.81 파크의 대표 액티비티, 무동력 친환경 레이싱!"/>
							</div>
						</div>	
					</div>
					<div className="visual-slide-text">
						<div className="inner">
							<div className="con">
								<div className="text-wrap color1">
									<p className="s-text typo1">ACTIVITY SPACE</p>
									<p className="m-text">{t('ActivityComp_title_1')} <br/> {t('ActivityComp_title_2')}</p>
								</div>
							</div>	
						</div>	
					</div>
				</div>
				<div className="detail-con-wrap">
					<div className="inner">
						<div className="list list-type1">
							<div className="img">
								<img src={"https://asset.981park.com/www/common/mweb/img/sub/park/img-map1" + (i18next.language === "en" ? "_en.png" : ".png")} alt="ACTIVITY SPACE"/>
							</div>
							<ul>
								<li><p className="ttl">{t('ActivityComp_c_1')}</p></li>
								<li>
									<p className="ttl">{t('ActivityComp_c_2')}</p>
									<p className="txt">
										<span className="hyphen">{t('ActivityComp_c_2_sub1')}</span><br/>
										<span className="hyphen">{t('ActivityComp_c_2_sub2')}</span><br/>
										<span className="hyphen">{t('ActivityComp_c_2_sub3')}</span><br/>
										<span className="hyphen">{t('ActivityComp_c_2_sub4')}</span><br/>
										<span className="hyphen">{t('ActivityComp_c_2_sub5_m1')}<br/>{t('ActivityComp_c_2_sub5_m2')}<br/> {t('ActivityComp_c_2_sub5_m3')}</span>
									</p>
								</li>
								<li><p className="ttl">{t('ActivityComp_c_5')}</p></li>
								<li><p className="ttl">{t('ActivityComp_c_8')}</p></li>
								<li><p className="ttl">{t('ActivityComp_c_7')}</p></li>
								<li><p className="ttl">{t('ActivityComp_c_9')}</p></li>
								<li><p className="ttl">{t('ActivityComp_c_6')}</p></li>
								<li><p className="ttl">{t('ActivityComp_c_4')}</p></li>
								<li><p className="ttl">{t('ActivityComp_c_3')}</p></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(MActivityComp);