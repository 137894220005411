var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { withTranslation, useTranslation } from "react-i18next";
import RoutePath from '../constant/route';
import i18next from "../i18n";
import './index.scss';
var MobileFooter = function MobileFooter() {
  var t = useTranslation().t;
  var changeLang = function changeLang(e) {
    i18next.changeLanguage(e.target.value);
    location.reload();
  };
  var scrollToTop = function scrollToTop() {
    return window.scrollTo(0, 0);
  };
  var TopButton = function TopButton() {
    if ((window === null || window === void 0 ? void 0 : window.location.pathname) === "/guide") {
      return null;
    }
    return _jsx("div", __assign({
      className: "link-top-wrap"
    }, {
      children: _jsx("a", __assign({
        onClick: scrollToTop,
        className: "link-top"
      }, {
        children: _jsx("span", __assign({
          className: "blind"
        }, {
          children: "top"
        }))
      }))
    }));
  };
  var openWindow = function openWindow() {
    window.open('https://race981.page.link/web_hero?utm_source=direct&utm_medium=button&utm_campaign=hero');
  };
  return _jsxs(_Fragment, {
    children: [_jsx(TopButton, {}), _jsx("footer", __assign({
      className: "mobile-footer"
    }, {
      children: _jsx("div", __assign({
        className: "footer-wrap"
      }, {
        children: _jsx("div", __assign({
          className: "inner"
        }, {
          children: _jsxs("div", __assign({
            className: "info-wrap"
          }, {
            children: [_jsxs("div", __assign({
              className: "info_policy"
            }, {
              children: [_jsxs("ul", __assign({
                className: "policy-list"
              }, {
                children: [_jsx("li", {
                  children: _jsx("a", __assign({
                    href: 'https://app.catchsecu.com/document/P/24f7518177892ee',
                    target: "_blank"
                  }, {
                    children: t('FooterComp_pri')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.TERMS,
                    style: {
                      cursor: "pointer"
                    }
                  }, {
                    children: t('FooterComp_terms')
                  }))
                }), _jsx("li", {
                  children: _jsx("a", __assign({
                    href: "/" + RoutePath.RULES,
                    style: {
                      cursor: "pointer"
                    }
                  }, {
                    children: t('FooterComp_rules')
                  }))
                })]
              })), _jsx("div", __assign({
                className: "btn-list"
              }, {
                children: _jsx("div", __assign({
                  className: "select-box-wrap"
                }, {
                  children: _jsxs("select", __assign({
                    className: "select-box",
                    value: i18next.language,
                    name: "",
                    id: "",
                    onChange: changeLang
                  }, {
                    children: [_jsx("option", __assign({
                      value: "ko"
                    }, {
                      children: t('FooterComp_lang_ko')
                    })), _jsx("option", __assign({
                      value: "en"
                    }, {
                      children: t('FooterComp_lang_en')
                    }))]
                  }))
                }))
              }))]
            })), _jsxs("div", __assign({
              className: "info-company"
            }, {
              children: [_jsxs("p", __assign({
                className: "text"
              }, {
                children: [_jsx("b", {
                  children: t('FooterComp_seoul_company')
                }), " ", _jsx("br", {}), t('FooterComp_seoul_company_1'), _jsx("br", {}), t('FooterComp_seoul_company_2'), _jsx("br", {}), t('FooterComp_seoul_company_3_1'), _jsx("br", {}), t('FooterComp_seoul_company_3_2')]
              })), _jsxs("p", __assign({
                className: "text"
              }, {
                children: [_jsx("b", {
                  children: t('FooterComp_jeju_company')
                }), " ", _jsx("br", {}), t('FooterComp_jeju_company_1'), _jsx("br", {}), t('FooterComp_jeju_company_2'), _jsx("br", {}), t('FooterComp_jeju_company_3_1'), _jsx("br", {}), t('FooterComp_jeju_company_3_2'), _jsx("br", {}), t('FooterComp_jeju_company_4'), _jsx("br", {})]
              }))]
            })), _jsxs("div", __assign({
              className: "info-copyright"
            }, {
              children: [_jsxs("div", __assign({
                className: "icon-list"
              }, {
                children: [_jsxs("div", __assign({
                  className: "sns-list"
                }, {
                  children: [_jsx("a", __assign({
                    href: 'https://www.facebook.com/981park',
                    target: "_blank",
                    className: "sns"
                  }, {
                    children: _jsx("img", {
                      src: "https://asset.981park.com/www/common/mweb/img/common/sns/00-common-footer-btn-01-facebook.svg",
                      alt: "facebook"
                    })
                  })), _jsx("a", __assign({
                    href: 'https://www.instagram.com/9.81park/',
                    target: "_blank",
                    className: "sns"
                  }, {
                    children: _jsx("img", {
                      src: "https://asset.981park.com/www/common/mweb/img/common/sns/00-common-footer-btn-02-instar.svg",
                      alt: "instar"
                    })
                  })), _jsx("a", __assign({
                    href: 'https://www.youtube.com/channel/UCdPUWUu5ZOWegvaqqwj7hfg',
                    target: "_blank",
                    className: "sns"
                  }, {
                    children: _jsx("img", {
                      src: "https://asset.981park.com/www/common/mweb/img/common/sns/00-common-footer-btn-03-youtube.svg",
                      alt: "youtube"
                    })
                  })), _jsx("a", __assign({
                    href: 'https://blog.naver.com/981jeju',
                    target: "_blank",
                    className: "sns"
                  }, {
                    children: _jsx("img", {
                      src: "https://asset.981park.com/www/common/mweb/img/common/sns/00-common-footer-btn-04-blog.svg",
                      alt: "blog"
                    })
                  }))]
                })), _jsxs("div", __assign({
                  className: "download-list"
                }, {
                  children: [_jsx("a", __assign({
                    onClick: openWindow,
                    className: "download"
                  }, {
                    children: _jsx("img", {
                      src: "https://asset.981park.com/www/common/mweb/img/common/sns/00-common-footer-btn-05-googleplay.svg",
                      alt: "Google play"
                    })
                  })), _jsx("a", __assign({
                    onClick: openWindow,
                    className: "download"
                  }, {
                    children: _jsx("img", {
                      src: "https://asset.981park.com/www/common/mweb/img/common/sns/00-common-footer-btn-06-apple.svg",
                      alt: "App Store"
                    })
                  })), _jsx("a", __assign({
                    href: 'https://asset.981park.com/apk/app-apk-release.apk',
                    target: "_blank",
                    className: "download"
                  }, {
                    children: _jsx("img", {
                      src: "https://asset.981park.com/www/common/mweb/img/common/sns/00-common-footer-btn-07-download.svg",
                      alt: "Download"
                    })
                  }))]
                }))]
              })), _jsx("div", __assign({
                className: "copyright"
              }, {
                children: _jsx("p", __assign({
                  className: "text"
                }, {
                  children: "Copyright \u24D2 MONOLITH & MONOLITH JEJU PARK INC.All rights reserved."
                }))
              }))]
            }))]
          }))
        }))
      }))
    }))]
  });
};
export default withTranslation()(MobileFooter);